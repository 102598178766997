import React, { useEffect } from "react";
import GroupAvatars from "../AvatarGroupLogos";
import SelectMenuItems from "../SelectMenuItems";
import SearchTextField from "../SearchTextField";
import { Box } from "@mui/material";
import { taskPriorityList, taskTypesList } from "../../utilities/Json";
import { useDispatch, useSelector } from "react-redux";
import { getSprintsList, setClearFilterAssignees } from "../../common/Redux/Reducer";
import { NormalSkeleton, CircularSkeleton } from "../../common/Skeleton";
import BoltIcon from '@mui/icons-material/Bolt';

const BacklogHeader = ({ setOpenAddPeopleModel = () => { }, searchTerm = '', handleSearch = () => { }, handleStatuUpdate = () => { }, selectedTaskType = '', handlePriorityUpdate = () => { }, selectPriorityType = '', EpicsData = [], handleEpicData = () => { }, selectedEpic = '' }) => {

  const dispatch = useDispatch();
  const { assignees, Skeleton } = useSelector((state) => state.ProjamAppSlice);

  const handleClearFilters = () => {
    handleSearch("")
    handleStatuUpdate("")
    handlePriorityUpdate("")
    handleEpicData('')
    setOpenAddPeopleModel(false);
    if (assignees?.length > 0) {
      dispatch(setClearFilterAssignees());
    }
  }
  const [menuTitle, setMenuTitle] = React.useState("Type");
  const [priorityTitle, setPriorityTitle] = React.useState("Priority")

  useEffect(() => {
    if (selectedTaskType.length === 0) {
      setMenuTitle("Type");
    } else {
      setMenuTitle(selectedTaskType);
    }
    if (selectPriorityType.length === 0) {
      setPriorityTitle("Priority");
    } else {
      setPriorityTitle(selectPriorityType);
    }
  }, [selectedTaskType, selectPriorityType]);

  const epicTitle = selectedEpic ? selectedEpic : 'Epic'
  const epicIcon = selectedEpic ? <span className="epic-icon-cls"><BoltIcon sx={{ color: '#fff', height: "0.5rem", maxHeight: '0.5rem', fontSize: '1.2rem' }} /></span> : null

  return (
    <Box className="d-flex justify-content-between align-items-center ps-2 pe-3 my-1" sx={{ width: '100%' }}>
      <Box className="d-flex flex-column flex-md-row align-items-md-center" sx={{ width: '100%' }}>
        <Box sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
        }}>
          {Skeleton ? <NormalSkeleton variant='rectangular' width={150} height={35} /> : <SearchTextField searchTerm={searchTerm} handleSearch={handleSearch} />}
        </Box>
        {Skeleton ? <CircularSkeleton /> : <GroupAvatars setOpenAddPeopleModel={setOpenAddPeopleModel}  />}
        <div className="d-flex align-items-center backlog-header-mobile-cls  mt-1">
          {Skeleton ? <NormalSkeleton variant='rectangular' width={50} height={25} /> : <SelectMenuItems title={epicTitle} icon={epicIcon} backGroundColor="#E3E4E9" handleStatuUpdate={handleEpicData} ItemsList={EpicsData} handleClearFilters={handleClearFilters} isEpicMenuList={true} />}
          {Skeleton ? <NormalSkeleton variant='rectangular' width={50} height={25} /> : <SelectMenuItems title={menuTitle} ItemsList={taskTypesList} handleStatuUpdate={handleStatuUpdate} backGroundColor="#E3E4E9" />}
          {Skeleton ? <NormalSkeleton variant='rectangular' width={50} height={25} /> : <SelectMenuItems title={priorityTitle} ItemsList={taskPriorityList} handlePriorityUpdate={handlePriorityUpdate} backGroundColor="#E3E4E9" />}
          {(assignees?.length > 0 || searchTerm || selectedTaskType || selectPriorityType || selectedEpic) ? <button className="clear-filters-btn-cls" onClick={handleClearFilters}>Clear filters</button> : null}
        </div>
      </Box>
    </Box>
  );
};

export default BacklogHeader;

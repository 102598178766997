import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Popover, Dialog, Tooltip, Badge } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import BacklogTable from "../BacklogTable";
import API_BASE_URL from "../config/appConfig";
import axios from "axios";
import { colors } from "../../utilities/colors";
import SprintStartModal from "./SprintStartModal";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { getSprintsList, getBacklogIssues, updateSprint } from "../../common/Redux/Reducer";
import CompletConfirmationModal from "../Modal/DeleteConfirmationModal"
import SelectMenuTextField from "../SelectMenuTextField";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import * as XLSX from 'xlsx';

const ProjectIssues = ({
  openGallery,
  onClick = () => { },
  name,
  sprint = null,
  issues = [],
  index = null,
  setSprintIndex = () => { },
  SprintIndex,
  sprintData = {},
  handleIssueClick = () => { },
  handleSprintIssueClick = () => { },
  searchTerm,
  AllsprintDetails = [],
  setOpenSelectedSprintIssue = () => { },
  IssuecountObj = {},
  isActiveSprintExist = true,
  setIsActiveSprintExist = () => { },
  activeSprint,
  selectedIssueType = '',
  selectPriorityType = '',
  selectedEpic = ''
}) => {
  const [issuesCount, setIssuesCount] = useState(issues.length);
  const [openModal, setOpenModal] = useState(false);
  const [sprintId, setSprinId] = useState(null);
  const [isSprintEditable, setIsSprintEditable] = useState(false)
  const dispatch = useDispatch();
  // const [openSprintActionsPopOver, setOpenSprintActionsPopOver] = useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectSprintanchorEl, setSelectSprintanchorEl] = useState(null)
  // const [openSelectSprintTomove, setOpenSelectSprintTomove] = useState(false)

  const filteredSprintDetails = AllsprintDetails.filter(sprint => sprint._id !== sprintData._id);
  // console.log(filteredSprintDetails, 'filteredSprintDetailsfilteredSprintDetailsfilteredSprintDetails');
  const options = name === "backlog" ? filteredSprintDetails : [...filteredSprintDetails, { _id: '123', name: "Move to Backlog", value: '123' }];
  const hasIssues = issues && issues.length > 0;
  const [issuesSelected, setIssuesSelected] = useState([])
  let openPopOver = Boolean(anchorEl);
  const id = openPopOver ? "simple-popover" : null;
  const openSelectSprint = Boolean(selectSprintanchorEl);
  const selectSprintId = openSelectSprint ? 'simple-popover-2' : undefined;

  const filterAssigneesArray = useSelector(
    (state) => state.ProjamAppSlice.assignees
  );
  const { Skeleton, Projects } = useSelector((state) => state.ProjamAppSlice)
  let backlogId = Projects?.selectedProject?.selectedProjectBackLogId
  // let projectId = Projects?.selectedProject?.selectedProjectData?._id

  const buttonStyles = {
    backgroundColor: "#60A630",
    color: "#fff",
    borderRadius: "8px",
    textTransform: "none",
    '&:hover': {
      backgroundColor: "#4b8e27",
    }
  };

  const DeletebuttonStyles = {
    backgroundColor: "red",
    color: "#fff",
    borderRadius: "8px",
    textTransform: "none",
    '&:hover': {
      backgroundColor: "red",
    }
  };

  // const IssuecountObj = AllsprintDetails[0]?.IssuecountObj |
  // console.log("AllsprintDetails==>",AllsprintDetails)
  // const [startSprintButton, setStartSprintButton] = useState();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  // const handleOpen = () => setOpenDeleteDialog(true);
  const handleClose = () => setOpenDeleteDialog(false);

  const handlePopOverClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };

  const handelMoveSprintPopOverClick = (event) => {
    setSelectSprintanchorEl(event.currentTarget)
  }
  const handelMoveSprintPopOverClose = () => {
    setSelectSprintanchorEl(null)
  }

  useEffect(() => {
    setIssuesCount(issues.length);
  }, [issues, isActiveSprintExist, AllsprintDetails]);

  const CreateNewSprint = async () => {
    try {
      await axios.post(`${API_BASE_URL}/sprints/createSprint`, {
        backlog: backlogId
      });
      await dispatch(getSprintsList({ backlogId: backlogId, filterAssigneesArray: filterAssigneesArray }));

    } catch (error) {
      console.log(error);
    }
  };
  const [modalConfig, setModalConfig] = useState({});

  const handleCompleteSprint = async (sprintId) => {
    try {
      await axios.put(`${API_BASE_URL}/sprints/updateSprint/${sprintId}`, {
        active: false,
        completed: true,
      });

      setIsActiveSprintExist(false)
    } catch (error) {
      console.log(error);
    }
    handleClose();
    dispatch(getSprintsList({ backlogId: backlogId, filterAssigneesArray: filterAssigneesArray }));
  };

  // const handleConfirm = () => {
  //   handleCompleteSprint(sprintData?._id);
  // };
  const openConfirmModal = (config) => {
    setModalConfig(config);
    setOpenDeleteDialog(true);
  };

  const handleConfirm = () => {
    if (modalConfig.action) {
      modalConfig.action(modalConfig.sprintId);
    }
    setOpenDeleteDialog(false);
  };

  const handleDeleteSprint = async (id) => {
    try {
      let Response = await axios.delete(`${API_BASE_URL}/sprints/deleteSprint/${id}`)
      await dispatch(getSprintsList({ backlogId: backlogId }));
    }
    catch (error) {
      console.log(error)
    }
    finally {
      handlePopOverClose()

    }
  }

  const handleStartSprint = async (values, sprintId) => {
    try {
      await axios.put(`${API_BASE_URL}/sprints/updateSprint/${sprintId}`, {
        active: true,
        completed: false,
      });
      await dispatch(getSprintsList({ backlogId: backlogId, filterAssigneesArray: filterAssigneesArray }));
      setIsActiveSprintExist(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handelMoveIssuesToSprint = async () => {
    try {
      let response = await axios.put(`${API_BASE_URL}/issues/moveto/sprint/${sprintId}`,
        { selectedIssues: issuesSelected }
      )

      setIsActiveSprintExist(true);
    }
    catch (error) {
      console.log(error)
    }
    finally {
      handelMoveSprintPopOverClose()
      dispatch(getSprintsList({ backlogId: backlogId, filterAssigneesArray: filterAssigneesArray }));
      dispatch(getBacklogIssues(backlogId));
    }
  }


  const handleDownload = async(issues, sprintName) => {

    try {
      let selectSprintIssues = await axios.post(`${API_BASE_URL}/sprints/download` , {
        sprintname:sprintName
      });
          const {issues} = selectSprintIssues?.data
      let reqData = issues?.map((issue) => {

        let { _id, sprint, project, backlog, comments, createdAt, updatedAt, __v, linkedissues, assignee, name, summary, description, storyPointEstimate, priority, label, status } = issue
        let assignedTo = assignee[0]?.employeeName || ""
        let DescriptionDiv = document.createElement("div");
        DescriptionDiv.innerHTML = description ? description : "";
        let puredescription = DescriptionDiv?.textContent || DescriptionDiv?.innerText || "";
        return { "Card ID": name, Title: summary, Description: puredescription ? puredescription : '', "Story Points": storyPointEstimate, Priority: priority, Label: label, "Assigned to": assignedTo, Status: status }
      })
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(reqData);
      XLSX.utils.book_append_sheet(wb, ws, `${sprintName}`);
      XLSX.writeFile(wb, `${sprintName}.xlsx`);

    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Box
      sx={{ flexGrow: 1, backgroundColor: "#F4F5F7", borderRadius: "5px" }}
      className="ms-1 mt-1 py-2 ms-md-3 mt-md-3 py-md-3 active-sprint-main-cls"
    >
      {/* <Box className=" d-flex align-items-center justify-content-between px-2 mb-3"> */}
      <Accordion
        defaultExpanded={true}
        className="issues-accordian-main-cls"
      >
        <div className={`d-flex justify-content-between ${issuesSelected?.length > 0 ? 'flex-column flex-sm-row align-items-start align-items-sm-center' : ''}`} style={{ paddingLeft: '12px', paddingRight: '12px' }}>
          <div className="d-flex align-items-center">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.3 rem !important' }} />}
              aria-controls={`panel1-content-${sprintData?._id}`}
              id={`panel1-header-${sprintData?._id}`}
              className="issues-accordian-summary-cls"
            >
            </AccordionSummary>
            <div className="d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between" style={{ paddingLeft: '12px', paddingRight: '12px' }}>
              {/* <div className='d-flex flex-column flex-sm-row align-items-start align-items-sm-center'> */}
              <Typography sx={{ fontSize: "14px" }}>
                <b className={`me-${name !== 'backlog' ? 0 : 1}`}>{name}</b> {name !== 'backlog' && <ModeEditIcon onClick={() => {
                  setOpenModal(true)
                  setIsSprintEditable(true)
                }} sx={{ marginLeft: "3px", fontSize: '14px', cursor: 'pointer', marginRight: '4px' }} />}
              </Typography>
              <Typography sx={{ fontSize: "14px", color: "#7B7C7B" }}>
                {sprintData?.startDate &&
                  sprintData?.endDate &&
                  `${moment(new Date(sprintData.startDate)).format(
                    "DD MMM"
                  )} - ${moment(new Date(sprintData.endDate)).format("DD MMM")}`}
                <span style={{ color: "#7B7C7B" }} className="ms-1">
                  {" "}
                  {issuesCount} Issues
                </span>
              </Typography>
              {/* </div> */}
            </div>
          </div>
          <Box className={`${issuesSelected?.length > 0 ? 'backlog-page-btn-grps-mobile-cls' : ''}`}>
            <Box className="d-flex align-items-center">
              {issuesSelected.length > 0 && <Button
                sx={{
                  backgroundColor: "#E3E4E9 !important",
                  border: "0px !important",
                  color: "#000000 !important",
                  borderRadius: "5px  !important",
                  textTransform: "none !important",
                }}
                aria-describedby={selectSprintId}
                onClick={(e) => handelMoveSprintPopOverClick(e)}
                className=" mx-1"
              >
                Move To Sprint
              </Button>}
              <Box sx={{ display: 'flex', gap: '8px', m: 1, }} className='d-none d-lg-flex'>
                {Object.entries(IssuecountObj).map(([status, count], index) => {
                  if (status === 'Done' || status === 'To Do' || status === 'In Progress') {
                    let buttonColor = '';
                    let tooltipText = status;
                    switch (status) {
                      case 'To Do':
                        buttonColor = 'lightgray';
                        tooltipText = 'To Do'
                        break;
                      case 'In Progress':
                        buttonColor = '#2196f3';
                        tooltipText = 'In Progress'
                        break;
                      case 'Done':
                        buttonColor = '#4caf50';
                        tooltipText = 'Done'
                        break;
                      default:
                        buttonColor = '#000000';
                        break;
                    }

                    return (
                      <Tooltip key={index} title={tooltipText} >
                        <span buttonColor={count} className="badge rounded-pill" style={{ padding: 3, margin: 0 }}>
                          <Button
                            sx={{
                              minWidth: 0,
                              height: '22px',
                              borderRadius: '50%',
                              padding: '7px',
                              backgroundColor: buttonColor,
                              color: '#ffffff',
                              '&:hover': {
                                backgroundColor: buttonColor,
                              },
                              fontSize: '12px !important'
                            }}
                          >
                            {count}
                          </Button>
                        </span>
                      </Tooltip>
                    );
                  }
                })}
              </Box>
              <Popover
                id={selectSprintId}
                open={openSelectSprint}
                anchorEl={selectSprintanchorEl}
                onClose={handelMoveSprintPopOverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                className=""
                PaperProps={{
                  sx: {
                    boxShadow: "none",
                    borderRadius: 0,
                    padding: 1,
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Box className="Avatar_PopOver_Box">
                  <Box sx={{ minWidth: "200px", padding: "10px" }} >
                    <SelectMenuTextField labelText='Select Sprint' options={options} onChange={(e) => setSprinId(e.target.value)} />
                    <Box className='d-flex justify-content-end mt-2'>
                      <Button variant="contained" color="success" disabled={!sprintId} onClick={() => handelMoveIssuesToSprint()}>Move</Button>
                    </Box>

                  </Box>

                </Box>

              </Popover>
              {name == "backlog" ? (
                <Button
                  sx={{
                    backgroundColor: "#E3E4E9 !important",
                    border: "0px !important",
                    color: "#000000 !important",
                    borderRadius: "5px  !important",
                    textTransform: "none !important",
                  }}
                  onClick={() => CreateNewSprint()}
                  className=" mx-1"
                >
                  Create sprint
                </Button>
              ) : (
                // index == "0" &&
                // isActiveSprintExist ?
                (sprintData?.active ? (
                  <Button
                    sx={{
                      backgroundColor: "#E3E4E9 !important",
                      border: "0px !important",
                      color: "#000000 !important",
                      borderRadius: "5px  !important",
                      textTransform: "none !important",
                    }}
                    // onClick={() => handleCompleteSprint(sprintData?._id)}
                    // onClick={handleOpen}
                    onClick={() => openConfirmModal({
                      title: `Are you sure you want to complete this ${sprintData?.name} sprint ?`,
                      description: "Are you sure you want to proceed?",
                      btnText: "Yes",
                      buttonStyles: buttonStyles,
                      action: handleCompleteSprint,
                      sprintId: sprintData?._id,
                      isCompleteConfirm: true,
                    })}
                    className=" mx-1"
                  >
                    Complete sprint
                  </Button>
                ) : (
                  <Button
                    disabled={!hasIssues || isActiveSprintExist}
                    sx={{
                      backgroundColor: !hasIssues
                        ? "#E3E4E9 !important"
                        : !isActiveSprintExist
                          ? `${colors.start_sprint_color} !important`
                          : "#E3E4E9 !important",
                      border: "0px !important",
                      color: !isActiveSprintExist && hasIssues ? "#FFFFFF !important" : undefined,
                      borderRadius: "5px  !important",
                      textTransform: "none !important",

                    }}
                    onClick={() => (
                      setOpenModal(true), setSprinId(sprintData?._id)
                    )}
                    className=" mx-1"
                  >
                    Start sprint
                  </Button>
                ))
              )
              }
              <Button
                sx={{
                  backgroundColor: "#E3E4E9 !important",
                  border: "0px !important",
                  color: "#000000 !important",
                  borderRadius: "5px  !important",
                }}
                aria-describedby={id}
                className="px-0 mx-1 d-none d-md-inline-block"
                onClick={(event) => handlePopOverClick(event)}
              >
                <MoreHorizIcon />

              </Button>
              <Popover
                id={id}
                open={openPopOver && name !== "backlog"}
                anchorEl={anchorEl}
                onClose={handlePopOverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                className=""
                PaperProps={{
                  sx: {
                    boxShadow: "none",
                    borderRadius: 0,
                    padding: 1,
                    backgroundColor: "transparent",
                  },
                }}
              >
                {name !== "backlog" && <Box className="Avatar_PopOver_Box p-2">
                  <Typography className="m-2" sx={{ cursor: "pointer" }}
                    // onClick={() => handleDeleteSprint(sprintData?._id)}
                    onClick={() => openConfirmModal({
                      title: `Are you sure you want to delete this sprint ${sprintData?.name}?`,
                      description: "Are you sure you want to proceed?",
                      btnText: "Yes",
                      buttonStyles: DeletebuttonStyles,
                      action: handleDeleteSprint,
                      sprintId: sprintData?._id,
                      isCompleteConfirm: false
                    })}
                  >Delete Sprint</Typography>
                  {issues && issues.length > 0 && <Typography className="m-2" sx={{ cursor: "pointer" }} onClick={() => handleDownload(issues, name)}>Download Sprint</Typography>}
                </Box>}

              </Popover>
            </Box>
          </Box>
        </div>
        <AccordionDetails className="px-0">
          {openGallery ? (
            <BacklogTable
              data={issues}
              sprint={sprint}
              handleIssueClick={handleIssueClick}
              handleSprintIssueClick={handleSprintIssueClick}
              searchTerm={searchTerm}
              setIssuesSelected={setIssuesSelected}
              selectedIssueType={selectedIssueType}
              selectPriorityType={selectPriorityType}
              selectedEpic={selectedEpic}
            />
          ) : null}
        </AccordionDetails>
      </Accordion>
      {openModal ? (
        <SprintStartModal
          setOpenModal={setOpenModal}
          handleStartSprint={() => handleStartSprint(sprintId)}
          sprintData={sprintData}
          isSprintEditable={isSprintEditable}
          setIsSprintEditable={setIsSprintEditable}
        />
      ) : null}

      {/* <CompletConfirmationModal
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        handleConfirm={handleConfirm}
        title="Complet Sprint ?"
        description="Are you sure you want to proceed?"
        btnText="Complete"
        buttonStyles={buttonStyles}
      /> */}
      <CompletConfirmationModal
        open={openDeleteDialog}
        handleClose={() => setOpenDeleteDialog(false)}
        handleConfirm={handleConfirm}
        title={modalConfig?.title}
        description={modalConfig?.description}
        btnText={modalConfig?.btnText}
        isCompleteConfirm={modalConfig?.isCompleteConfirm}
        buttonStyles={modalConfig?.buttonStyles}
        activeSprint={activeSprint}
        AllsprintDetails={AllsprintDetails}
        setOpenDeleteDialog={setOpenDeleteDialog}
        sprintId={modalConfig?.sprintId}
        isActiveSprintExist={isActiveSprintExist}
        setIsActiveSprintExist={setIsActiveSprintExist}
      />
    </Box>
  );
};

export default ProjectIssues;

import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Typography } from "@mui/material";
import Microsofticon from "../../assets/images/microsoft_732221.png";
import Googleicon from "../../assets/images/google_300221.png";
import { NavLink, useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppTitleLogo from "../../assets/images/projam-icon.svg";
import LockIcon from "@mui/icons-material/Lock";
import "./home.scss";
import API_BASE_URL from "../../components/config/appConfig";
import { loginSchema } from "../../Validations/schema";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { jwtDecode } from "jwt-decode";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Login = () => {

  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const LogoText = styled(Box)`
    font-weight: 700;
    letter-spacing: 1.4px;
    color: ##010101;
  `;
  const RegisterText = styled(NavLink)`
   font-size:13px !important;
  &:hover {
  color: #60A630 !important;
    text-decoration: underline !important;
  }
`;

  const ForgotPassText = styled(NavLink)`
  color: #000;
  font-size:13px !important;
  &:hover {
    color: #60A630 !important;
  }
`;


  const loginCardStyles = {
    backgroundColor: "#ffffff",
    borderRadius: "20px",
    width: "30%",
    margin: "0 auto",
    padding: "20px",
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // const handleSubmit = async (values) => {
  //   const loginData = {
  //     email: values.email,
  //     password: values.password,
  //   };
  //   try {
  //     const response = await fetch(`${API_BASE_URL}/users/login`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(loginData),
  //     });
  //     if (response.ok) {
  //       const data = await response.json();
  //       console.log(data, "Logindata");

  //       if (
  //         // data.email === "shivani.kujala@motivitylabs.com" ||
  //          data.email === "sushmitha.gopari@motivitylabs.com") {
  //         localStorage.setItem("accessToken", data.accessToken);
  //         navigate('/admin/dashboard')
  //       }
  //       if (data.accessToken) {
         

  //         navigate("/projects");
  //       } else {
  //         setError(
  //           "Invalid login attempt. Please check your credentials and try again."
  //         );
  //         setSnackbarOpen(true);
  //       }
  //     } else {
  //       let responsess = await response.json()
  //       const errorMessage = await responsess.message;
  //       setError(errorMessage);
  //       setSnackbarOpen(true);
  //     }
  //   } catch (error) {
  //     console.log(error, 'catch')
  //     setError("An error occurred. Please try again later.");
  //     setSnackbarOpen(true);
  //   }
  // };


  // useEffect(() => {
  //   const token = localStorage.getItem('accessToken');
    
  //   if (token) {
  //     try {
  //       const adminDecodedToken = jwtDecode(token); 
  //       if (
  //         // adminDecodedToken.email === "shivani.kujala@motivitylabs.com" || 
  //         adminDecodedToken.email === "sushmitha.gopari@motivitylabs.com") {
  //         navigate("/admin/dashboard");
  //       } else {
  //         navigate("/projects");
  //       }
  //     } catch (error) {
  //       console.error("Invalid token or token decoding failed", error);
  //     }
  //   } else {
  //     // navigate("/login");
  //   }
  // }, []);

  const handleSubmit = async (values) => {
    const loginData = {
      email: values.email,
      password: values.password,
    };
  
    try {
      const response = await fetch(`${API_BASE_URL}/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });
  
      if (response.ok) {
        const data = await response.json();
        // console.log(data, "Logindata");
        localStorage.setItem("accessToken", data.accessToken);
        if (data.email === "sushmitha.gopari@motivitylabs.com") {
          navigate("/admin/dashboard");
        } else {
          navigate("/projects");
        }
      } else {
        const responsess = await response.json();
        const errorMessage = await responsess.message;
        setError(errorMessage);
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Login error:", error);
      setError("An error occurred. Please try again later.");
      setSnackbarOpen(true);
    }
  };
  
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
  
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.email === "sushmitha.gopari@motivitylabs.com") {
          navigate("/admin/dashboard");
        } else {   
          navigate("/projects");
        }
      } catch (error) {
        console.error("Invalid token or token decoding failed", error);
        
      }
    } else {
    
      // navigate("/login");
    }
  }, [navigate]);
  
  

  return (
    <Box className='login-page-cls'>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={error}
        anchorOrigin={{ vertical: 'top', horizontal: 'right', width: '100px' }}
        autoHideDuration={6000}
      >
        <Alert onClose={handleSnackbarClose}
          severity="error"
          iconMapping={{
            error: <ErrorOutlineIcon style={{ fontSize: 25 }} />,
          }}
          sx={{
            width: '300px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {error}
        </Alert>
      </Snackbar>
      <Box
        sx={loginCardStyles}
        className="d-flex flex-column p-5 shadow login-card-styles"
      >
        <Typography
          noWrap
          className="text-center mb-1 d-flex align-items-center justify-content-center"
        >
          <img
            src={AppTitleLogo}
            alt="App-Title-Logo"
            height={44}
            className="me-1"
          />
          <LogoText fontSize={'20px !important'}>Projam Software</LogoText>
        </Typography>
        <Typography className="text-center mb-2 mt-0" sx={{ fontWeight: 600 }}>
          Login to continue
        </Typography>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={loginSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, error }) => (
            <Form>
              <Field name="email">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Email*"
                    variant="outlined"
                    size='small'
                    placeholder="Enter email address"
                    fullWidth
                    className="mt-2"
                    InputProps={{
                      // endAdornment: <EmailIcon />,
                      startAdornment: <EmailIcon className="me-1" />,
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="email"
                component="span"
                sx={{ fontSize: "12px" }}
                className="error-message text-danger"
              />

              <Field name="password">
                {({ field }) => (
                  <TextField
                    {...field}
                    label="Password*"
                    variant="outlined"
                    size='small'
                    fullWidth
                    placeholder="Enter password"
                    type="password"
                    className="mt-3"
                    InputProps={{
                      // endAdornment: <LockIcon />,
                      startAdornment: <LockIcon className="me-1" />,
                    }}
                  />
                )}
              </Field>
              <ErrorMessage
                name="password"
                component="span"
                sx={{ fontSize: "12px" }}
                className="error-message text-danger"
              />

              {error && <div className="error-message">{error}</div>}

              <Button
                type="submit"
                variant="contained"
                fullWidth
                className="mt-3 mb-1 Login-btn"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Logging in..." : "Continue"}
              </Button>
            </Form>
          )}
        </Formik>
        <div className="d-flex align-items-center justify-content-between">
          <RegisterText to='/register' >Register Here!</RegisterText>
          <ForgotPassText to='/forgot-password'>Forgot Password?</ForgotPassText>
        </div>
        <b className="text-center">Or</b>
        <Typography className="text-center my-1" sx={{ fontSize: '13px' }}>
          Continue with:
        </Typography>

        <Button
          className="text-center social-login-btns my-2 mt-0 py-2"
        >
          <img src={Googleicon} alt="Google icon" className="me-2" /> Google
        </Button>
        <Button
          className="text-center social-login-btns my-2 py-2"
        >
          <img src={Microsofticon} alt="Microsoft icon" className="me-2" /> Microsoft
        </Button>
      </Box>
    </Box>
  );
};

export default Login;

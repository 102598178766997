import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Tooltip } from "@mui/material";
import { Typography, Box } from '@mui/material';

const IssueTypeMenuList = ({
  selectedValue = {
    name: '',
    icon: ''
  },
  ItemsList = [],
  showEndIcon = true,
  setSelectedValue = () => { },
  isbtnTextActive = false,
  isActiveSprintPage = false
}) => {
  const [gallery, setGallery] = useState(null);

  const handleClick = (event) => {
    setGallery(event.currentTarget);
  };

  const handleChangeSelectedValue = (value) => {
    setSelectedValue(value);

    setGallery(null);
  };

  const handleClose = () => {
    setGallery(null);
  };

  const open = Boolean(gallery);

  return (
    <div
      className={`${isActiveSprintPage ? 'create-issue-type-menu-list-main-cls' : 'issue-type-menu-list-main-cls'}`}
    >
      <Tooltip title={selectedValue?.name}>
        <Button
          onClick={handleClick}
          className="select-issue-type-btn mx-1"
          // startIcon={icon}
          endIcon={
            showEndIcon ? open ? <ExpandLessIcon className="" /> : <ExpandMoreIcon className="" /> : null
          }
        >
          <Box className="icon-hover-box d-flex align-items-center" >
            {selectedValue?.icon}
            {isbtnTextActive ? <Typography className="mx-2">{selectedValue?.name}</Typography> : null}
          </Box>
        </Button>
      </Tooltip>
      <Menu anchorEl={gallery} open={open} onClose={handleClose}>
        {(ItemsList && ItemsList?.length > 0) ? ItemsList?.map((eachItem, index) => (
          <MenuItem
            onClick={() => handleChangeSelectedValue(eachItem)}
            key={index}
            className="px-3"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              fontSize: "13px",
            }}
          >
            {(eachItem?.icon) ? (eachItem?.icon) : ''}
            {eachItem?.name}
          </MenuItem>
        )) : <MenuItem onClick={() => handleClose()}> No Data Available</MenuItem>}
      </Menu>
    </div>
  );
};

export default IssueTypeMenuList;

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography, Button, ClickAwayListener, Popover, TextField } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import { getIssueTypeIcon, getPriorityIcon } from '../../utilities/Icons';
import { Tooltip } from "@mui/material";
import { taskTypesList } from '../../utilities/Json';
import BackgroundLetterAvatarsLogo from '../AvatarLogo';
import { Textarea } from '@mui/joy';
import IssueTypeMenuList from '../../common/IssueTypeMenuList';
import { FiPlus } from "react-icons/fi";
import { IssueStatusCardSkeleton, NormalSkeleton, CircularSkeleton } from '../../common/Skeleton';
import { TbArrowBigRight } from "react-icons/tb";
import { BsBox, BsHourglassSplit } from "react-icons/bs";
import InReviewImg from "../../assets/images/in-review-img.svg";
import { BsCardChecklist } from "react-icons/bs";
import ProductionReadyImg from "../../assets/images/production-ready-img.svg";
import DoneIcon from '../../assets/images/DoneIcon.png';
import SyncIcon from '@mui/icons-material/Sync';
import SearchableDropdown from '../Modal/SearchableDropDown';
import IconButton from "@mui/material/IconButton";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { getLastFiveActiveSprintInfo, getSprintsList, SetlastfiveActiveSprintInfo, updateIssue } from '../../common/Redux/Reducer';

const TasksList = [
    {
        title: "To Do",
        icon: <TbArrowBigRight color="#391085" />,
        bgColor: "#39108526",
        numberOfIssues: 6,
    },
    {
        title: "In Progress",
        icon: <BsHourglassSplit color="#4E77B2" />,
        bgColor: "#4E77B226",
        numberOfIssues: 3,
    },
    {
        title: "In Review",
        icon: (
            <img src={InReviewImg} alt="InReviewImg" width="18px" height="18px" />
        ),
        bgColor: "#FF854726",
        numberOfIssues: 0,
    },
    {
        title: "QA",
        icon: <BsCardChecklist color="#6B54E5" />,
        bgColor: "#6B54E526",
        numberOfIssues: 1,
    },
    {
        title: "Production Ready",
        icon: (
            <img
                src={ProductionReadyImg}
                alt="ProductionReadyImg"
                width="18px"
                height="18px"
            />
        ),
        bgColor: "#52C41A26",
        color: "#52C41A",
        numberOfIssues: 0,
    },
    {
        title: "Done",
        icon: (
            <img src={DoneIcon} alt="Done" width="18px" height="18px" />
        ),
        bgColor: "#52C41A26",
        color: "#52C41A",
        numberOfIssues: 4,
    },
];

const defaultIcon = <SyncIcon color="#000" />;
const defaultBgColor = "#E0E0E0";

const EachTask = styled(Paper)(({ theme }) => ({
    backgroundColor: '#F4F6F9',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'start',
    color: theme.palette.text.secondary,
    minHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '1px solid #CCCCCC',
    borderRadius: '12px',
    // minWidth: '238px',
    width: '100%'

}));
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#FFFFFF',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'start',
    color: theme.palette.text.secondary,
    minHeight: '75px',
    border: '1px solid #EAEAEA',
    borderRadius: '8px',
    // minWidth:'180px'
    // maxWidth:'195px',


}));
const matchingTask = (title) => {
    return (
        TasksList.find((task) => task.title === title) || {
            icon: defaultIcon,
            bgColor: defaultBgColor,
        }
    );
};



const ActiveSprintAssigneesLayout = ({
    searchTerm = '', activeSprintId = '',
    issueStatusList = [],
    handleCreateIssueClick = () => { },
    isEditing = false,
    currentTaskIndex = 0,
    issueType = "Task",
    setIssueType = () => { },
    handleChangeIssueTitle = () => { },
    handleClickAway = () => { },
    handleTextareaSubmit = () => { },
    onChangeModalStatus = () => { },
    // handleColumnDrop = () => { },
    draggedTodoItem = {},
    selectedIssueType = '',
    selectPriorityType = '',
    newTaskTitle = '',
    handleSaveClick,
    handleSaveIssue = () => { },
    handleCancelIssue = () => { },
    handleEditClick = () => { },
    handleDeleteTaskStatus = () => { },
    editingTaskIndex = '',
    setNewTaskTitle = () => { },
    showInput = '',
    handleAddTaskItem = () => { },
    inputValue = '',
    setInputValue = () => { },
    handleCancelClick = () => { },
    inputRef = ''


}) => {

    const { Projects, assignees, Skeleton, assigneeLayoutSkeleton } = useSelector((state) => {
        return state.ProjamAppSlice;
    });

    const SprintData = Projects?.selectedProject?.activeSprintAssigneesLayoutData
    const contributors = Projects?.selectedProject?.contributors
    const userDetails = Projects?.selectedProject?.loggedUserDetails
    let backlogId = Projects?.selectedProject?.selectedProjectBackLogId
    const filterAssigneesArray = useSelector((state) => state.ProjamAppSlice.assignee)
    let projectId = Projects?.selectedProject?.selectedProjectData?._id

    const [data, setData] = useState(SprintData?.AssigneGroupObj || [])


    // console.log(data , 'data')
    const [Employee, setEmployee] = useState('');
   

    const dispatch = useDispatch()
    const activeSprint = Projects?.selectedProject?.ActiveSprint;

    const [issueCountObj , setIssueCountObj]=useState(activeSprint?.IssuecountObj||{})

    useEffect(() => {
        if (SprintData?.AssigneGroupObj?.length > 0) {
            if (assignees && assignees?.length > 0) {
                let filteredAssigneesData = SprintData?.AssigneGroupObj?.filter((eachEmployee) =>
                    (assignees?.includes(eachEmployee?.employeeDetails?.employeeName) || eachEmployee?.employeeDetails?.employeeName === "unassigned")
                );

                // Filter issues based on searchTerm
                filteredAssigneesData = filteredAssigneesData?.map((eachEmployee) => {
                    return {
                        ...eachEmployee,
                        assignedIssues: eachEmployee?.assignedIssues?.filter((eachIssue) =>
                            eachIssue?.summary?.toLowerCase().includes(searchTerm.toLowerCase())
                        )
                    };
                });
                if (selectedIssueType) {
                    const fitleredIssueTypeData = filteredAssigneesData?.map((eachEmployee) => {
                        return {
                            ...eachEmployee,
                            assignedIssues: eachEmployee?.assignedIssues?.filter((eachIssue) => eachIssue?.issueType?.toLowerCase() === selectedIssueType?.toLowerCase())
                        }
                    });
                    setData(fitleredIssueTypeData)
                }
                if (selectPriorityType) {
                    const fitleredIssueTypeData = filteredAssigneesData?.map((eachEmployee) => {
                        return {
                            ...eachEmployee,
                            assignedIssues: eachEmployee?.assignedIssues?.filter((eachIssue) => eachIssue?.priority?.toLowerCase() === selectedIssueType?.toLowerCase())
                        }
                    });
                    setData(fitleredIssueTypeData)
                }

                else {
                    console.log('Filtered Assignees Data:', filteredAssigneesData);
                    setData(filteredAssigneesData)
                }
            } else {
                if (selectedIssueType) {
                    const fitleredIssueTypeData = SprintData?.AssigneGroupObj?.map((eachEmployee) => {
                        return {
                            ...eachEmployee,
                            assignedIssues: eachEmployee?.assignedIssues?.filter((eachIssue) => eachIssue?.issueType?.toLowerCase() === selectedIssueType?.toLowerCase())
                        }
                    })
                    setData(fitleredIssueTypeData);
                } else {
                    setData(SprintData?.AssigneGroupObj)
                }
            }
        }
    }, [assignees, selectedIssueType, SprintData, searchTerm, selectPriorityType]);


    const [anchorEl, setAnchorEl] = useState({ id: "", element: null });

    const handlePopOverClick = (event, rowId) => {
        setAnchorEl({ id: rowId, element: event.currentTarget });
    };

    const handlePopOverClose = () => {
        setAnchorEl({ id: "", element: null });
    };



    const getUpdatedBurnDownInfo = async () => {
        try {
          let response = await dispatch(getLastFiveActiveSprintInfo({ backlogId, projectId }));
          dispatch(SetlastfiveActiveSprintInfo(response?.payload))
    
        }
        catch (error) {
          console.log(error)
        }
      }

  
const handleColumnDrop = async (status = '', draggedItemType) => {
    
    if (!draggedTodoItem?.current) return;


    let customArray = [...data];
    // console.log(customArray, 'customArray---');
    let reqAssignee = customArray.find((each) => 
        each.assignedIssues.find((issue) => issue._id == draggedTodoItem?.current?.id)
    );

    if (!reqAssignee) return;

   
    let reqIssue = reqAssignee.assignedIssues.find((issue) => 
        issue._id == draggedTodoItem?.current?.id
    );

    let sts = reqIssue.status
    let dummyIssueCountObj = {...issueCountObj};
   
    if(sts){
   
    dummyIssueCountObj = {...dummyIssueCountObj , [status]:dummyIssueCountObj[status]+1 ,[sts]:dummyIssueCountObj[sts]-1 }
    }
    

    reqIssue = { ...reqIssue, status };

   
    let indexOfAssigneeObj = customArray.findIndex((eachAssignee) => 
        eachAssignee.employeeDetails.employeeId == reqAssignee.employeeDetails.employeeId
    );

    
    let updatedAssignedIssues = [...customArray[indexOfAssigneeObj].assignedIssues];
    let indexOfIssue = updatedAssignedIssues.findIndex((issue) => 
        issue._id == draggedTodoItem?.current?.id
    );


    updatedAssignedIssues[indexOfIssue] = reqIssue;

   
    customArray[indexOfAssigneeObj] = {
        ...customArray[indexOfAssigneeObj],
        assignedIssues: updatedAssignedIssues,
    };

    setData(customArray); 
    setIssueCountObj(dummyIssueCountObj)

    // Handle status update and dispatch actions
    if (draggedTodoItem?.current?.status?.toLowerCase() !== status.toLowerCase() && draggedItemType === draggedTodoItem?.current?.draggedItemType) {
        // await dispatch(setSkeletonStatus({ Skeleton: true }));
         await dispatch(updateIssue({ issueId: draggedTodoItem?.current?.id, payload: { status } }));
         await dispatch(getSprintsList({ backlogId, filterAssigneesArray }));
        // await dispatch(setSkeletonStatus({ Skeleton: false }));
        getUpdatedBurnDownInfo();
    }

    // Clear draggedTodoItem reference
    draggedTodoItem.current = null;
};







    const handleAssigneeUpdate = async (taskId, selectedUser) => {
        dispatch(updateIssue({ issueId: taskId, payload: { assignee: selectedUser ? [selectedUser] : [] } }));
        dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray: [] }));
        handlePopOverClose();

    };
    const userAccess = (userDetails.role === 'Project Manager' || userDetails.role === 'Project Lead' || userDetails.role === 'Scrum Master' || userDetails.role === 'Project Owner') ? true : false

    return (
        <div className='my-3 px-3'>
            <Grid container spacing={1} sx={{ flexWrap: 'nowrap', marginBottom: "10px" }} >
                {issueStatusList?.map((eachTask, index) => {
                    const taskDetails = matchingTask(eachTask.title);
                    return (
                        <Grid item xs={12} sm={6} md={4} lg={2} key={eachTask.title} sx={{ flexShrink: 0 }}>
                            <Box key={eachTask?.title} sx={{}} >
                                {!Skeleton ? (
                                    <Item
                                        className="shadow-none d-flex align-items-center"
                                        sx={{
                                            position: 'relative',
                                            '&:hover .task-status-icon': { opacity: 1 },
                                        }}
                                    >
                                        <IconButton
                                            color="inherit"
                                            sx={{
                                                backgroundColor: `${taskDetails.bgColor} !important`,
                                                marginRight: '10px',
                                            }}
                                        >
                                            <Box>{taskDetails.icon}</Box>
                                        </IconButton>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    {userAccess && isEditing && editingTaskIndex === index ? (
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                            <TextField
                                                                value={newTaskTitle}
                                                                onChange={(e) => setNewTaskTitle(e.target.value)}
                                                                autoFocus
                                                                size="small"
                                                                variant="outlined"
                                                                sx={{ marginRight: '8px', flexGrow: 1 }}
                                                            />
                                                            <Box sx={{
                                                                display: 'flex',
                                                                flexDirection: 'row', justifyContent: 'flex-end', ml: '8px'
                                                            }}>
                                                                <IconButton color="primary" onClick={() => handleSaveIssue(index, eachTask._id)}>
                                                                    <CheckIcon />
                                                                </IconButton>
                                                                <IconButton color="secondary" onClick={handleCancelIssue}>
                                                                    <CloseIcon />
                                                                </IconButton>
                                                            </Box>
                                                        </Box>
                                                    ) : (
                                                        <p
                                                            className="each-task-status-title-cls mb-1"
                                                            style={{ margin: 0, cursor: 'pointer', flexGrow: 1 }}
                                                        >
                                                            {eachTask?.title}
                                                        </p>
                                                    )}

                                                    {userAccess && !isEditing && editingTaskIndex !== index && (
                                                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                                                            {issueCountObj[eachTask?.title] == 0 && (<EditIcon
                                                                className="edit-icon task-status-icon"
                                                                onClick={() => handleEditClick(index, eachTask?.title)}
                                                                sx={{ cursor: 'pointer', opacity: 0, transition: 'opacity 0.3s ease' }}
                                                            />)}
                                                            {issueCountObj[eachTask?.title] == 0 &&
                                                                (<DeleteIcon
                                                                    className="delete-icon task-status-icon"
                                                                    onClick={() => handleDeleteTaskStatus(eachTask?._id)}
                                                                    sx={{ marginLeft: '8px', cursor: 'pointer', opacity: 0, transition: 'opacity 0.3s ease' }}
                                                                />)}
                                                        </Box>
                                                    )}
                                                </Box>
                                                {!isEditing || editingTaskIndex !== index ? (
                                                    <p className="each-tasks-cls mb-0">
                                                        {issueCountObj && issueCountObj[eachTask?.title]}
                                                    </p>
                                                ) : null}
                                            </Box>
                                        </Box>
                                    </Item>



                                ) : (
                                    <IssueStatusCardSkeleton isStatus={true} />
                                )}
                            </Box>
                        </Grid>)
                })
                }
                {userAccess && <div className="d-flex flex-column  justify-content-start">
                    {!showInput ? (
                        issueStatusList?.length < 8 && (
                            <Box
                                sx={{
                                    minWidth: 100,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginTop: '10px'
                                }}
                            >
                                <IconButton color="primary" onClick={handleAddTaskItem}
                                    sx={{ backgroundColor: 'rgb(227, 228, 233)' }}>
                                    <FiPlus />
                                </IconButton>
                            </Box>
                        )
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: 100, marginLeft: '8px', marginTop: '8px' }} >
                            <TextField
                                variant="outlined"
                                size="small"
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                fullWidth
                                onFocus={() => inputRef.current?.focus()}
                                sx={{ width: '150px' }}
                            />
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                mt: 0
                            }}>
                                <IconButton color="primary" onClick={handleSaveClick}>
                                    <CheckIcon />
                                </IconButton>
                                <IconButton color="secondary" onClick={handleCancelClick}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    )}
                </div>}

            </Grid>
            {data?.map((eachEmployee, index) =>
                eachEmployee?.assignedIssues?.length > 0 || eachEmployee?.employeeDetails?.employeeName === "unassigned" ? <Box
                    sx={{ flexGrow: 1 }}
                    className=" my-0"
                    key={`${eachEmployee?.employeeDetails?.employeeId}-${index}`}
                >
                    {(!Skeleton && !assigneeLayoutSkeleton) ? <Accordion
                        defaultExpanded={true}
                        className="issues-accordian-main-cls"
                    >
                        <div className="d-flex align-items-center">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.3 rem !important' }} />}
                                aria-controls={`panel1-content-${eachEmployee?.employeeDetails?.employeeId}`}
                                id={`panel1-header-${eachEmployee?.employeeDetails?.employeeId}`}
                                className="issues-accordian-summary-cls"
                            >
                                <div className='d-flex align-items-center'>
                                    <BackgroundLetterAvatarsLogo LogoTitle={eachEmployee?.employeeDetails?.employeeName} RequiredFontSize='10px !important' extraIdName="assignee-layout-avatar-cls" />
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <b className="me-1">{eachEmployee?.employeeDetails?.employeeName}</b>
                                        ({eachEmployee?.assignedIssues?.length === 1 ? `${eachEmployee?.assignedIssues?.length} issue` : `${eachEmployee?.assignedIssues?.length} issues`})
                                    </Typography>
                                </div>
                            </AccordionSummary>
                        </div>
                        <AccordionDetails className="px-0">
                            <Grid
                                container
                                spacing={1}
                                // columns={{ xs: 4, sm: 8, md: 12 }}
                                // justifyContent="space-between"
                                alignItems="stretch"
                                flexWrap={'nowrap'}
                            >

                                {issueStatusList?.map((eachTask, index) => (

                                    <Grid item xs={12} sm={6} md={4} lg={2} key={eachTask.title} sx={{ flexShrink: 0 }}>
                                        <EachTask
                                            className="shadow-none p-1 task-card-hover-cls"
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={(e) =>
                                                handleColumnDrop(
                                                    eachTask.title,
                                                    // eachProjectTask.name
                                                )
                                            }
                                        >
                                            {eachEmployee?.assignedIssues?.map((eachIssue, index) =>
                                                eachTask?.title?.toLowerCase() ===
                                                    eachIssue?.status?.toLowerCase() ? (
                                                    <Box
                                                        key={eachIssue?._id}
                                                        sx={{
                                                            backgroundColor: "#FFFFFF",
                                                            borderRadius: "5px",
                                                            minHeight: "50px",
                                                            width: "100%",
                                                            border: "1px solid #E3E3E3",
                                                            transition: "background-color 0.3s ease",
                                                            "&:hover": {
                                                                backgroundColor: "#69e6a680",
                                                                cursor: "grab",
                                                            },
                                                        }}
                                                        className="pt-2 pb-1 px-2 d-flex flex-column justify-content-between task-item mb-1"
                                                        // draggable={eachIssue?.status?.toLowerCase() !== 'done'}
                                                        draggable
                                                        onDragStart={(e) =>
                                                            // (draggedTodoItem.current = eachIssue._id)
                                                            (draggedTodoItem.current = { id: eachIssue?._id, status: eachIssue?.status })
                                                        }
                                                        onDragOver={(e) => e.preventDefault()}
                                                    >
                                                        <div className='d-flex align-items-center'>
                                                            <Tooltip
                                                                title={eachIssue?.issueType || "Story"}
                                                            >
                                                                {getIssueTypeIcon(eachIssue?.issueType)}
                                                            </Tooltip>
                                                            <p className='ms-1 mb-0 issue-para-name-cls'>{eachIssue?.name}</p>
                                                        </div>

                                                        <Typography
                                                            className="task-title"
                                                            onClick={() =>
                                                                onChangeModalStatus(eachIssue)
                                                            }
                                                        >
                                                            {eachIssue?.summary}
                                                        </Typography>
                                                        <div
                                                            className="d-flex justify-content-between align-items-center mt-2"
                                                            style={{ width: "100%" }}
                                                        >
                                                            <Tooltip title={"Story point"}>
                                                            <span className="badge rounded-pill">
                                                                <Button className="StoryPoint_badge">
                                                                {eachIssue?.storyPointEstimate}
                                                                </Button>
                                                            </span>
                                                            </Tooltip>
                                                            <Tooltip
                                                                title={eachIssue?.priority || "Urgent"}
                                                            >
                                                                {getPriorityIcon(eachIssue?.priority)}
                                                            </Tooltip>
                                                            <div >
                                                                <Button size="small"
                                                                    className="assingeeBtnDropdown_main"
                                                                    aria-describedby={eachIssue?._id}
                                                                    onClick={(event) => handlePopOverClick(event, eachIssue?._id)}
                                                                >
                                                                    <BackgroundLetterAvatarsLogo LogoTitle={eachEmployee?.employeeDetails?.employeeName !== "unassigned" ? eachEmployee?.employeeDetails?.employeeName : ''} RequiredFontSize='8px !important' extraIdName="assignee-layout-issue-card-avatar-cls" />
                                                                </Button>
                                                                <Popover
                                                                    id={eachIssue?._id}
                                                                    open={anchorEl?.id === eachIssue?._id}
                                                                    anchorEl={anchorEl?.element}
                                                                    onClose={handlePopOverClose}
                                                                    anchorOrigin={{
                                                                        vertical: "bottom",
                                                                        horizontal: "left",
                                                                    }}
                                                                    PaperProps={{
                                                                        sx: {
                                                                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                                                            borderRadius: 0,
                                                                            padding: 1,
                                                                            width: 270,
                                                                        },
                                                                    }}
                                                                >
                                                                    <Box>
                                                                        <SearchableDropdown
                                                                            workFlowModalTaskData={eachIssue}
                                                                            members={contributors}
                                                                            handleAssigneeUpdate={handleAssigneeUpdate}
                                                                        />
                                                                    </Box>
                                                                </Popover>
                                                            </div>
                                                        </div>
                                                    </Box>
                                                ) : null
                                            )}
                                            {index === currentTaskIndex && isEditing && Employee?.employeeId === eachEmployee?.employeeDetails?.employeeId ? (
                                                <ClickAwayListener onClickAway={handleClickAway}>
                                                    <form
                                                        onSubmit={(event) => {
                                                            if (eachEmployee?.employeeDetails?.employeeName === "unassigned") {
                                                                handleTextareaSubmit(event, eachTask.title, [])
                                                            } else {
                                                                handleTextareaSubmit(event, eachTask.title, eachEmployee?.employeeDetails)
                                                            }
                                                        }
                                                        }
                                                    >
                                                        <Textarea
                                                            color="success"
                                                            placeholder="What needs to be done?"
                                                            className="sprint-grid-layout-create-issue-input"
                                                            minRows={2}
                                                            maxRows={10}
                                                            autoFocus
                                                            onChange={handleChangeIssueTitle}
                                                            endDecorator={
                                                                <div
                                                                    className="d-flex justify-content-between align-items-end"
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    <IssueTypeMenuList
                                                                        labelText="Issue type"
                                                                        ItemsList={taskTypesList}
                                                                        setSelectedValue={setIssueType}
                                                                        selectedValue={issueType}
                                                                        isActiveSprintPage={true}

                                                                    />
                                                                    <Button
                                                                        type="submit"
                                                                        sx={{ ml: "auto" }}
                                                                        size="small"
                                                                        variant="contained"
                                                                        color="success"
                                                                        className="sprint-grid-layout-create-issue-btn"
                                                                    >
                                                                        Create
                                                                    </Button>
                                                                </div>
                                                            }
                                                        />
                                                    </form>
                                                </ClickAwayListener>
                                            ) : (
                                                <button
                                                    onClick={() => {
                                                        setEmployee(eachEmployee?.employeeDetails)
                                                        handleCreateIssueClick(index)

                                                    }}
                                                    // className={`${index !== 0 ? "d-none" : ""
                                                    //     } active-sprints-create-issue-btn-cls py-2`}
                                                    className={` active-sprints-create-issue-btn-cls py-2`}
                                                >
                                                    <FiPlus className="me-1" />
                                                    Create Issue
                                                </button>
                                            )}
                                        </EachTask>
                                    </Grid>))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion> :
                        <div>
                            <div className='d-flex align-items-center my-2'>
                                <CircularSkeleton />
                                <NormalSkeleton width='200px' extraStyles=" ms-2" />
                            </div>
                            <Grid
                                container
                                spacing={{ xs: 2, md: 1 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                                justifyContent="space-between"
                                alignItems="stretch"
                            >
                                {issueStatusList?.map((eachTask, index) => (
                                    <Grid item xs={2} sm={2} md={2} key={eachTask.title}>
                                        <EachTask
                                            className="shadow-none p-1 task-card-hover-cls">
                                            <IssueStatusCardSkeleton />
                                        </EachTask>
                                    </Grid>))}
                            </Grid>
                        </div>}

                </Box> : null
            )}

        </div>
    )
}


export default ActiveSprintAssigneesLayout
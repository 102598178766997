import React, { useState } from 'react';
import { Modal, Box, IconButton, Button, Typography, Avatar, ListItemAvatar } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
// import CloseIcon from '@mui/icons-material/Close';
// import BackgroundLetterAvatarsLogo from '../AvatarLogo';
import { useEffect } from 'react';
import axios from 'axios';
import API_BASE_URL from '../config/appConfig';
import { useDispatch, useSelector } from 'react-redux';
import { getUserNotifications } from '../../common/Redux/Reducer';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { jwtDecode } from 'jwt-decode';
import moment from "moment";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Drawer from '@mui/joy/Drawer';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import ModalClose from '@mui/joy/ModalClose';
import Badge from '@mui/material/Badge';
import Skeleton from '@mui/material/Skeleton';

const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
};

const NotificationComponent = () => {
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState('unread');

    const dispatch = useDispatch();
    const [usreNotifications, setUserNotifications] = useState([])
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { user } = useSelector((state) => {
        return state.ProjamAppSlice;
    });
    let userNotifications = user?.notifications
    const [loading, setLoading] = useState(false)
    // let decoded = {}



    useEffect(() => {
        getNotificatoins()
        //   let token = localStorage.getItem('accessToken');
        //   if (token) {
        //     decoded = jwtDecode(token)
        //   }

    }, [])


    const getNotificatoins = async () => {
        setLoading(true)
        try {
            let notifications = await dispatch(getUserNotifications())
            setLoading(false);
        }
        catch (error) {
            console.log(error)
            setLoading(false);
        }
    }

    useEffect(() => {
        filterNotifications()
    }, [userNotifications, filter])

    const filterNotifications = () => {
        setLoading(true)
        let decoded = {}
        let token = localStorage.getItem('accessToken');
        if (token) {
            decoded = jwtDecode(token)
        }

        if (filter == 'unread') {

            let filteredNotifications = userNotifications?.map((notificationobj) => {

                let req = []
                let filterNotifications = notificationobj?.receivers?.some((receiverObj) => {
                    if (receiverObj?.receiver == `${decoded?.email}` && receiverObj?.read == false) {
                        req.push(notificationobj)
                    }
                })
                return req
            })
            filteredNotifications = filteredNotifications?.filter((each) => each?.length)

            setUserNotifications(filteredNotifications)
        }
        if (filter == 'all') {

            let filteredNotifications = userNotifications?.map((notificationobj) => {

                let req = []
                let filterNotifications = notificationobj?.receivers?.some((receiverObj) => {
                    if (receiverObj?.receiver == `${decoded?.email}`) {
                        req.push({
                            ...notificationobj,
                            isUnread: receiverObj?.read === false,
                        });
                    }
                });
                return req
            })
            filteredNotifications = filteredNotifications?.filter((each) => each?.length)
            setUserNotifications(filteredNotifications)
        }
        setLoading(false)
    }


    const updateReadStatus = async (id) => {
        setLoading(true)
        let decoded = {}
        let token = localStorage.getItem('accessToken');
        if (token) {
            decoded = jwtDecode(token)
        }
        try {
            let updateReadStatus = await axios.post(`${API_BASE_URL}/notification/updateReadstatus`,
                {
                    notificationId: id,
                    Email: `${decoded.email}`
                }
            )
            setLoading(false)
            filterNotifications()
            getNotificatoins()
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    return (
        <div>
            <Badge
                badgeContent={usreNotifications?.length > 0 ? usreNotifications.length : null}
                color="error"
                sx={{
                    '& .MuiBadge-badge': {
                        fontSize: '10px !important',
                        minWidth: '16px',
                        height: '18px',
                        padding: "0 4px",
                    }
                }}
            >
                <NotificationsNoneIcon
                    onClick={() => {
                        setOpen(true);
                        setFilter('unread');
                        filterNotifications();
                    }}
                    sx={{ margin: "0px 0px 0px 10px ", color: 'black', cursor: 'pointer' }}
                />
            </Badge>

            <Drawer open={open} onClose={() => {
                setOpen(false);
                setFilter('unread');
            }}
                anchor="right"
                className='notification-drawer-cls'
            >
                <ModalClose sx={{ mt: 1 }} />
                <DialogTitle className='notify-close'>Notifications</DialogTitle>
                <Box className='notify-focused' sx={{ mt: 1, mb: 1 }}>
                    <Button
                        onClick={() => setFilter('unread')}
                        variant={filter === 'unread' ? 'contained' : 'outlined'}
                    >
                        UNREAD
                    </Button>
                    <Button
                        onClick={() => setFilter('all')}
                        variant={filter === 'all' ? 'contained' : 'outlined'}
                    >
                        All
                    </Button>
                </Box>
                <DialogContent sx={{ backgroundColor: '#F9F8F7' }}>
                    <Box sx={{ p: 1 }}>
                        {loading ? (
                            [...Array(7)].map((_, index) => (
                                <Box
                                    key={index}
                                    className="notification-card"
                                    sx={{ display: 'flex', alignItems: 'center', mb: 2 }}
                                >
                                    <Skeleton variant="circular" width={40} height={40} />
                                    <Box sx={{ flex: 1, ml: 2 }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                flexDirection: 'row',
                                                gap: '1px',
                                            }}
                                        >
                                            <Skeleton width="50%" height={25} />
                                        </Box>
                                        <Skeleton width="80%" height={20} />
                                    </Box>
                                </Box>
                            ))
                        ) : usreNotifications?.length ? (
                            usreNotifications.map((notification) => (
                                <Box
                                    key={notification[0]._id}
                                    className="notification-card"
                                    onClick={() => {
                                        if (filter === 'unread') {
                                            updateReadStatus(notification[0]._id)
                                        }
                                    }
                                    }
                                >
                                    <Avatar>
                                        <NotificationsActiveOutlinedIcon />
                                    </Avatar>
                                    <Box sx={{ flex: 1, ml: 2 }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                flexDirection: 'column',
                                                gap: '1px',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    flexDirection: 'row',
                                                    gap: '1px',
                                                }}
                                            >
                                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                                    {notification[0]?.notification?.title}
                                                </Typography>
                                                <Typography sx={{ fontWeight: 200, fontSize: '12px !important' }}>
                                                    {moment(notification[0]?.createdAt).format('MM/DD/YYYY hh:mm A')}
                                                    <span>
                                                        {/* {filter === 'unread' ? (
                                                                <FiberManualRecordIcon sx={{ fontSize: 15 }} />
                                                            ) : null} */}
                                                        {notification[0]?.isUnread || filter === 'unread' ? ( // Check if the notification is unread
                                                            <FiberManualRecordIcon sx={{ fontSize: 15 }} />
                                                        ) : null}
                                                    </span>
                                                </Typography>
                                            </Box>
                                            <Typography variant="body2" color="textSecondary">
                                                {notification[0]?.notification?.body}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            ))
                        ) : null}
                    </Box>
                </DialogContent>
            </Drawer>

        </div>
    );
};

export default NotificationComponent;

import React from 'react'
import { Box, Button } from '@mui/material'
import styled from 'styled-components'
import Grid from "@mui/material/Grid";
import landingPageImg from '../../assets/images/hero-img-main.svg';
import ProSubImg from '../../assets/images/Pro-sub.png';
import ProSubImgOne from '../../assets/images/Pro-sub1.png';
import ProSubImgTwo from '../../assets/images/Pro-sub2.png';
import CheckIco from '../../assets/images/check.png';
import ServiceImg from '../../assets/images/service1.png';
import ServiceImgOne from '../../assets/images/service2.png';
import ServiceImgTwo from '../../assets/images/service3.png';
import ArrowRight from '../../assets/images/arrow-right.png';

import { NavLink } from 'react-router-dom';
import { colors } from '../../utilities/colors';

const LandingPage = () => {

    const LandingPageTopContainer = styled.div`
    background-color: #0A2640;
    // background: linear-gradient(30deg, #d5f0d3 15%, #eff3d9 40%, #d5f0d3);
    min-height:85vh;
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    @media (max-width: 768px) {
    min-height:55vh;
       
    }
    `
    const Title1 = styled.h4`
    font-size:44px;
    color:#fff;
    font-weight:700;
    `
    const Paragraph1 = styled.p`
    font-size:20px;
    color:#fff;
    font-weight:400;
    `
    return (
        <Box >
            <LandingPageTopContainer>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box className="" sx={{ width: "70%" }}>
                            <Title1 className='p-3'>Productivity, Streamline Workflows, Foster Collaboration</Title1>
                            <Paragraph1 className='px-3'>Projam is a comprehensive project management application designed to follow agile process.</Paragraph1>
                            <NavLink to="/login">
                                <Button
                                    className='px-4 ms-3 bg-contained-btn-cls' variant='contained'
                                >
                                    Get Started
                                </Button>
                            </NavLink>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} className='d-none d-md-flex'>
                        <Box className="d-flex align-items-center" sx={{ width: "75%" }}>
                            <img src={landingPageImg} alt='Home bg' width="100%" />
                        </Box>
                    </Grid>
                </Grid>

            </LandingPageTopContainer>


            <div className='servies'>
                <div className='container mt-2 mt-md-5'>
                    <div className='row justify-content-center'>
                        <div className='col-md-10'>
                            <div className='row'>
                                <div className='text-center mb-4 mb-md-5'>
                                    <h6>Our Services</h6>
                                    <h1>The ultimate tool to connect every team, task, and project.</h1>
                                </div>
                                <div className='col-md-4'>
                                    <img src={ServiceImg} className="img-fluid2 w-100" alt="ServiceImg" />
                                    <h2>Issue and Bug Tracking</h2>
                                    <p>Learning curve network effects return on investment.</p>
                                    <ul>
                                        <li> Explore page <img src={ArrowRight} alt='ArrowRight' width="25px" /></li>
                                    </ul>
                                </div>
                                <div className='col-md-4'>
                                    <div className='border-0'>
                                        <img src={ServiceImgOne} className="img-fluid2 w-100" alt="ServiceImg" />
                                        <h2>Real-Time Collaboration</h2>
                                        <p>Learning curve network effects return on investment.</p>
                                        <ul>
                                            <li> Explore page <img src={ArrowRight} alt='ArrowRight' width="25px" /></li>
                                        </ul>

                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div className='border-0'>
                                        <img src={ServiceImgTwo} className="img-fluid2 w-100" alt="ServiceImg" />
                                        <h2>Customizable Workflow</h2>
                                        <p>Learning curve network effects return on investment.</p>
                                        <ul>
                                            <li> Explore page <img src={ArrowRight} alt='ArrowRight' width="25px" /></li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className='job_grid'>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-md-4 offset-md-1'> <img src={ProSubImg} className="img-fluid2 w-100" alt="ProSubImg" /> </div>
                        <div className='col-md-5 offset-md-1'>
                            <div className='border-0 mt-5'>
                                <h2>Keep everyone informed with clear visibility into project status and updates.</h2>
                                <ul>
                                    <li><img src={CheckIco} alt='CheckIco' width="30px" /> Schedule and manage tasks effectively</li>
                                    <li><img src={CheckIco} alt='CheckIco' width="30px" /> Ensure work align with goals</li>
                                    <li><img src={CheckIco} alt='CheckIco' width="30px" /> Customize task tracking</li>
                                </ul>
                                <button className='border-0 btn btn-cost btn-primary mt-4'>Start Now</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='discover-blk'>
                <div className='container mt-5'>
                    <div className='row'>
                        <div className='col-md-5 offset-md-1'>
                            <div className='border-0 mt-5'>
                                <h2>Discover why Projam is the preferred choice for professionals across industries</h2>
                                <ul className='mt-5'>
                                    <li><img src={CheckIco} alt='CheckIco' width="30px" /> Schedule and manage tasks effectively</li>
                                    <li><img src={CheckIco} alt='CheckIco' width="30px" /> Ensure work align with goals</li>
                                    <li><img src={CheckIco} alt='CheckIco' width="30px" /> Customize task tracking</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-md-4 offset-md-1'> <img src={ProSubImgOne} className="w-100 " alt="ProSubImgOne" /> </div>
                    </div>
                </div>
            </div>

            <div className='connect-job'>
                <div className='container mt-5'>
                    <div className='row justify-content-center'>
                        <div className='col-md-10'>
                            <img src={ProSubImgTwo} className="img-fluid mb-5" alt="ProSubImgTwo" />
                            <div className='row'>
                                <div className='col-md-6'>
                                    <h2>We connect our customers with the best, and help them keep up-and stay open.</h2>
                                </div>
                                <div className='col-md-5 offset-md-1'>
                                    <div className='border-0 mt-2'>
                                        <ul>
                                            <li>We connect our customers with the best? <img src={CheckIco} className='drop-ico' alt='CheckIco' width="30px" /></li>
                                            <li>Android research & development rockstar? <img src={CheckIco} className='drop-ico' alt='CheckIco' width="30px" /></li>
                                        </ul>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className='bottom-sec'>
                <div className='container mb-5'>
                    <div className='row justify-content-center'>
                        <div className='col-md-10 bottom-inn'>
                            <h2>Feel free to reach out to us with any additional questions. Our team is here to help!</h2>
                            <div className='row justify-content-center mt-4'>
                                <div className='col-md-6 col-xl-4'>
                                    <input className='w-100' type="text" placeholder="Your email address"></input>
                                </div>
                                <div className='col-md-4 mt-2 mt-md-0 col-xl-2'>
                                    <button type='button' className='info-btn'>Start Now</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </Box>
    )
}

export default LandingPage

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import BreadcrumbsComponent from "../BreadcrumbsComponent";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import SearchTextField from "../SearchTextField";
import ShareIcon from "@mui/icons-material/Share";
import FilterListIcon from "@mui/icons-material/FilterList";
import MenuIcon from "@mui/icons-material/Menu";
import AllIssuesTable from "./AllIssuesTable";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SelectMenuItems from "../SelectMenuItems";
import { GroupList, MoreList } from "../../utilities/Json";
import { colors } from "../../utilities/colors";
import GroupAvatars from "../AvatarGroupLogos";
import AddPeopleToProject from "../../containers/Dashboard/ProjectPages/AddPeopleToProject";
import { useSelector } from "react-redux";
import { NormalSkeleton, CircularSkeleton } from "../../common/Skeleton";
import { useLocation } from 'react-router-dom'
import axios from "axios";
import SelectMenuTextField from "../SelectMenuTextField";
import * as XLSX from 'xlsx';
import API_BASE_URL from "../config/appConfig";
const btnGroupBtnStyles = {
  backgroundColor: "transparent !important",
  border: "0px !important",
  color: "#000000 !important",
};

const IssuesList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [openAddPeopleModel, setOpenAddPeopleModel] = useState(false);
  const [sprintId, setSprintId] = useState("");
  const [issues, setIssues] = useState([]);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ShowCopied, setShowCopied] = useState(false);
  const [sprintName, setSprintName] = useState("");

  let ProjectName = localStorage.getItem("projectName");
  const { Projects, Skeleton } = useSelector((state) => state.ProjamAppSlice);
  const backlogId = Projects?.selectedProject?.selectedProjectBackLogId
  const IssuesInBacklog = Projects?.selectedProject?.backlogIssuesList;
  //const sprintDataList = Projects?.selectedProject?.sprintsList || [];

  const [sprintdropdownOptions, setSprintDropDownOptions] = useState([])
  //= [{ _id: '123', value: '123', name: 'All Issues' }, ...sprintOptions];

  const handleSelectChange = (e) => {
    e.preventDefault();
    const selectedValue = e.target.value;
    setSprintId(selectedValue);
    const selectedSprint = sprintdropdownOptions?.find(sprint => sprint.value === selectedValue);
    setSprintName(selectedSprint ? selectedSprint.name : "All Issues");
    setShowDownloadButton(true);
  };


  useEffect(() => {
    if (backlogId) {
      getSprintData(backlogId)
    }

  }, [backlogId])

  const getSprintData = async (backlogId) => {
    try {
      let response = await axios(`${API_BASE_URL}/sprints?backlogId=${backlogId}`)
      let sprintData = response?.data
      const sprintOptions = sprintData?.map((sprint) => ({ _id: sprint?._id, value: sprint?._id, name: sprint?.name })).reverse();
      let options = [{ _id: '123', value: '123', name: 'All Issues' }, ...sprintOptions];
      setSprintDropDownOptions(options)

    }
    catch (error) {
      console.log(error, 'error')
    }
  }


  const handleSearch = (text) => {
    setSearchTerm(text);
  };

  const handleDownload = async() => {
    try {
let selectSprintIssues = await axios.post(`${API_BASE_URL}/sprints/download` , {
  sprintname:sprintName
});
    const {issues} = selectSprintIssues?.data
      const reqData = issues?.map((issue) => {
        const {
          name,
          summary,
          description,
          storyPointEstimate,
          priority,
          label,
          status,
          assignee
        } = issue;
        const assignedTo = assignee[0]?.employeeName || "";
        const DescriptionDiv = document?.createElement("div");
        DescriptionDiv.innerHTML = description ? description : " ";
        const puredescription = DescriptionDiv?.textContent || DescriptionDiv?.innerText || "";

        return {
          "Card ID": name,
          Title: summary,
          Description: puredescription,
          "Story Points": storyPointEstimate,
          Priority: priority,
          Label: label,
          "Assigned to": assignedTo,
          Status: status
        };
      });

      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(reqData);
      XLSX.utils.book_append_sheet(wb, ws, sprintName);
      XLSX.writeFile(wb, `${sprintName}.xlsx`);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCopyUrl = () => {
    try {
      navigator.clipboard.writeText(window.location.href)
      setShowCopied(true)
    }
    catch (error) {

    }
  }

  return (
    <div className="list-page-container-cls">
        <div className="ps-2"> {!Skeleton ? <BreadcrumbsComponent /> : <NormalSkeleton width={200} extraStyles="my-2" />}</div>
        <Box className="my-2 px-3 d-flex justify-content-between align-items-center">
          <Typography
            sx={{ fontSize: "18px !important", fontWeight: 600, fontFamily: "roboto" }}
          >
            Issues List
          </Typography>
          <ButtonGroup
            variant="contained"
            aria-label="outlined button group"
            className="shadow-none"
          >
            <Button sx={btnGroupBtnStyles} className="px-0 mx-1">
              <CampaignOutlinedIcon className="me-2" /> Give Feedback
            </Button>
          </ButtonGroup>
        </Box>
        <Box className="d-flex justify-content-between align-items-center ps-2 pe-3 my-3">
          <Box className="d-flex align-items-center">
          <Box sx={{
              display: { xs: 'none', sm: 'none', md: 'block' },
            }}>
            {!Skeleton ? 
            <SearchTextField
              searchTerm={searchTerm}
              handleSearch={handleSearch}
            /> : <NormalSkeleton variant='rectangular' width={200} height="30px" extraStyles="my-2" />}
            </Box>
            {!Skeleton ? <GroupAvatars setOpenAddPeopleModel={setOpenAddPeopleModel} /> : <CircularSkeleton />}
          </Box>
          <Box sx={{
              display: { xs: 'none', sm: 'none', md: 'block' },
            }}>
          {!Skeleton ? (
            <Box className="px-4 d-flex justify-content-between align-items-center">
              <SelectMenuTextField
                labelText="Download Sprint"
                options={sprintdropdownOptions}
                value={sprintId}
                onChange={handleSelectChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 29 * 5 + 5,      
                    },
                  },
                }}
              />
              {showDownloadButton &&
                <Button
                  sx={{
                    backgroundColor: `${colors.primary} !important`,
                    border: "0px !important",
                    color: "#FFFFFF !important",
                    borderRadius: "5px  !important",
                    fontSize: "10px !important",
                    textTransform: "capitalize",
                    marginLeft: "10px"
                  }}
                  className="px-3"
                  type="submit"
                  onClick={handleDownload}
                >
                  Download
                </Button>
              }
            </Box>
          ) : (
            <NormalSkeleton variant='rectangular' width={300} height="30px" extraStyles="my-2" />
          )}
        </Box>
        </Box>
      {!loading && (
        // <Box
        //   sx={{
        //     top: 0,
        //     zIndex: 1,
        //     width: "100%",
        //     maxWidth: "1250px",
        //     backgroundColor: "#F4F5F7",
        //     borderRadius: "5px",
        //     ms: 3,
        //     py: 3,
        //   }}
        // >
          <AllIssuesTable
            searchTerm={searchTerm}
            // listIssues={issues}
            setIssues={setIssues}
            setSprintName={setSprintName}
            sprintId={sprintId}
            sprintName={sprintName}
            issues={issues}
          />
        // </Box>
      )}
      {openAddPeopleModel && (
        <AddPeopleToProject
          open={openAddPeopleModel}
          ProjectName={ProjectName}
          setOpenAddPeopleModel={setOpenAddPeopleModel}
        />
      )}
    </div>
  );
};

export default IssuesList;



import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ReferenceLine } from 'recharts';
import moment from 'moment';

const BurndownChart = ({burnDownChartData}) => {

const [burnDownData ,setBurnDownData]=useState([])
const [TotalStoryPoins,setTotalStoryPoints]=useState(0);
const [backupRemaingWork, setBackupRemainingWork]=useState(0);

useEffect(() => {
  if (burnDownChartData.length > 0) {
    getDatesOftheSprint(burnDownChartData[0]?.startDate, burnDownChartData[0]?.endDate);
    getTotalStoryPoints(burnDownChartData[0]?.issues);
  }
}, [burnDownChartData]);



const getHowmanyWeekOffDays =async(duration , startDate ,endDate)=>{
try{
let DatesArray = await Promise.all(Array.from({length:duration}).map((defaultCurrElem,i)=>{
  let nextDate = new Date(startDate);
  nextDate.setDate(nextDate.getDate() + i);
  return nextDate

}))

let WeekoffDayArray =  DatesArray.filter((eachDate)=>{
  return moment(eachDate).format("ddd") == "Sat" || moment(eachDate).format("ddd") =='Sun'

})

return WeekoffDayArray?.length

}
catch(error){
return 0
}
}


let currentCoordinate = burnDownChartData[0]?.initiallyplannedStoryPoints;
const getActualPlannedData = async (i, day) => {
  try {
    let actualPlannedStoryPoints = burnDownChartData[0]?.initiallyplannedStoryPoints || 0;
    let start = new Date(burnDownChartData[0]?.startDate);
    let end = new Date(burnDownChartData[0]?.endDate);

    const startDate = moment(start);
    const endDate = moment(end);
    const duration = endDate.diff(startDate, "days");

    let WeekOffCount = await getHowmanyWeekOffDays(duration - 1, start, end);
    let totalWorkingDays = duration - WeekOffCount;
    let PlannedWorkPerDay = Math.ceil((actualPlannedStoryPoints || 1) / (totalWorkingDays || 1));

    if (day === 'Sat' || day === 'Sun') {
      if(currentCoordinate<=0){
        return 0
      }
      return currentCoordinate;
    } else {
      currentCoordinate -= PlannedWorkPerDay;
      if(currentCoordinate<=0){
        return 0
      }
      return currentCoordinate;
    }
  } catch (error) {
    // console.log(error.message, 'getActualPlannedData error');
    return 0;
  }
};


const getTotalStoryPoints = async(issues)=>{
 
  try{
let value= issues.map((each)=>{

  return  each?.storyPointEstimate||0
})

let totalStoryPoins =  value.reduce((c,a)=>{
  return c+a
})

setTotalStoryPoints(totalStoryPoins)
  }
  catch(error){

  }
}

const getremainginwork = (date)=>{
  try{

 let requiredObj =   burnDownChartData[0]?.burndowninfo.filter((each)=>{
 
     return moment(each.date).format("MMM DD") == date

    })

    if(requiredObj?.length==0){
      return null
    }
    setBackupRemainingWork(requiredObj?.reverse()[0]?.remainingWork ) 
  
    return requiredObj[0].remainingWork

  }
  catch(error){
console.log(error ,'error ')
  }
}


// let bckupRemainingworkArray =[]
// const getremainginwork = (date)=>{
//   try{
 
//  let requiredObj =   burnDownChartData[0]?.burndowninfo.filter((each)=>{
 
//      return moment(each?.date).format("MMM DD") == date
 
//     })
 
// //console.log(new Date(date) < new Date(burnDownChartData[0]?.burndowninfo[burnDownChartData[0]?.burndowninfo.length-1].date) , "check")
 
 
//     if(requiredObj?.length==0 
//      // && new Date(date) < new Date(burnDownChartData[0]?.burndowninfo[burnDownChartData[0]?.burndowninfo.length-1].date)
//      ){
   
//       return bckupRemainingworkArray[bckupRemainingworkArray.length-1]
//     }
//     bckupRemainingworkArray.push(requiredObj[0].remainingWork)
//     return requiredObj[0].remainingWork
 
//   }
//   catch(error){
// console.log(error ,'error ')
//   }
// }



const getDatesOftheSprint = async(start , end)=>{
  try{
    const startDate = moment(start);
    const endDate = moment(end);
    const duration = endDate.diff(startDate, "days");
   let data = await Promise.all(Array.from({length:duration+1}).map(async(defaultCurrElem ,i)=>{
    let nextDate = new Date(startDate);
    nextDate.setDate(nextDate.getDate() + i);
return  { date: moment(nextDate).format("MMM DD"), remainingWork: getremainginwork(moment(nextDate).format("MMM DD")),actual: await getActualPlannedData(i, moment(nextDate).format("ddd"))  , day:moment(nextDate).format("ddd")}


}))
 setBurnDownData(data)
  }
  catch(error){
    console.log(error?.message)
  }
}


  return (
    <LineChart
      width={1200}
      height={500}
      data={burnDownData}
      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
    >
     
      <XAxis dataKey="date" 
      interval={0}
       />
      {burnDownData.map((each, index) => (
       
        (each.day === "Sat" || each.day === "Sun") && (
          <ReferenceLine
            key={index} 
            x={each?.date} 
            strokeOpacity={0.3}
            fill="#d3d3d3"
            strokeWidth={1200 / (burnDownData?.length || 1)} 
            name="week off"
            label={{
              value: 'Week Off',
              position: 'insidemiddle', 
              fill: '#000', 
              fontSize: 10, 
               dy: -10 
              
            }}
          />
        )
      ))}
      <YAxis  
      label={{ value: 'Story Points', angle: -90, position: 'insideLeft'  }} 
       tickCount={8}
         />
    <Tooltip/>
      <Legend />
      <Line type="step" dataKey="remainingWork" stroke="#ff7300" name="remainingWork" />
      <Line type="step" dataKey="actual" stroke="#A3A3A4" name="actual"  dot={false} /> 
    </LineChart>
  );
};

export default BurndownChart;

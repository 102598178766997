import * as Yup from "yup";

export const loginSchema = Yup.object().shape({
  email: Yup.string().email("Enter valid Email").required("Enter Email"),
  password: Yup.string()
    .min(8, "Enter valid Password")
    .required("Enter Password"),
});
export const EmailVerificationSchema = Yup.object().shape({
  email: Yup.string().email("Enter valid Email").required("Enter Email"),
});
export const updatePasswordSchema = Yup.object().shape({
  email: Yup.string().email("Enter valid Email").required("Enter Email"),
  password: Yup.string()
    .min(8, "Enter valid Password")
    .required("Enter Password"),
  confirmPassword: Yup.string()
    .min(8, "Enter valid Password")
    .required("Enter Password"),

});

export const registerSchema = Yup.object().shape({
  email: Yup.string().email("Enter valid Email").required("required*"),
  password: Yup.string()
    .min(8, "Password should contain minimum 8 characters")
    .required("required*"),
  name: Yup.string().required("required*"),
  // role: Yup.string().required("required*"),
  department: Yup.string().required("required*"),
});

export const backlogissueSchema = Yup.object().shape({
  project: Yup.string().required("required*"),
  issueType: Yup.string().required("required*"),
  summary: Yup.string().required("required*"),
  description: Yup.string(),
});

export const sprintSchema = Yup.object().shape({
  name: Yup.string().required("required*"),
  duration: Yup.string().required("required*"),
  startDate: Yup.date().required("required*"),
  endDate: Yup.date().required("required*"),
  sprintGoal: Yup.string().required("required*"),
});

export const createProjectSchema = Yup.object().shape({
  title: Yup.string().required("Title is required*"),
  key: Yup.string().required("Key is required*"),
  lead: Yup.string().required("Lead is required*"),
})
import React, { useState, useEffect } from 'react';
import { Autocomplete, TextField, Button, IconButton, InputBase, Chip } from '@mui/material';
import API_BASE_URL from "../../components/config/appConfig";
import "./modal.css";
import CheckIcon from '@mui/icons-material/Check';

const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const LabelsDropdown = ({ labels, onLabelChange, className, workFlowModalTaskData, projectDetails }) => {
    const [selectedLabel, setSelectedLabel] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState(labels || []);
    const [editLabelId, setEditLabelId] = useState(null);
    const [editLabelValue, setEditLabelValue] = useState('');
    const [labelColors, setLabelColors] = useState({});

    useEffect(() => {
        const colors = {};
        options?.forEach(option => {
            colors[option.name] = getRandomColor();
        });
        setLabelColors(colors);
    }, [options]);

    useEffect(() => {
        setOptions(labels || []);
    }, [labels]);

    useEffect(() => {
        if (workFlowModalTaskData?.label) {
            const matchedOption = (options || []).find(option => option?.name === workFlowModalTaskData?.label);
            if (matchedOption) {
                setSelectedLabel(matchedOption);
            } else {
                setSelectedLabel({ name: workFlowModalTaskData?.label, value: workFlowModalTaskData?.label });
                setOptions(prevOptions => [...(prevOptions || []), { name: workFlowModalTaskData?.label, value: workFlowModalTaskData?.label }]);
            }
        } else {
            setSelectedLabel(null);
        }
    }, [workFlowModalTaskData, options]);
    

    const handleAddLabel = async () => {
        const projectId = localStorage.getItem('projectId');
        try {
            const newLabel = { name: inputValue, value: inputValue };
            const updatedLabels = [...projectDetails.labels, newLabel];
            const response = await fetch(`${API_BASE_URL}/projects/updateProject/${projectId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ labels: updatedLabels }),
            });

            if (!response.ok) throw new Error('Network response was not ok');
            const addedLabel = await response.json();
            console.log("addedLabel=>", addedLabel.labels)
            setOptions(prevOptions => [...prevOptions, newLabel]);
            setSelectedLabel(newLabel);
            setInputValue('');
            if (onLabelChange) onLabelChange(newLabel);
        } catch (error) {
            console.error('Error adding new label:', error);
            // alert('Failed to add new label. Please try again.');
        }
    };

    const handleEditLabel = async () => {
        const projectId = localStorage.getItem('projectId');
        try {
            const updatedLabels = options.map(label =>
                label.name === editLabelId ? { ...label, name: editLabelValue, value: editLabelValue } : label
            );
            const response = await fetch(`${API_BASE_URL}/projects/updateProject/${projectId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ labels: updatedLabels }),
            });

            if (!response.ok) throw new Error('Network response was not ok');
            setOptions(updatedLabels);
            setSelectedLabel({ name: editLabelValue, value: editLabelValue });
            setEditLabelId(null);
            setEditLabelValue('');
            if (onLabelChange) onLabelChange({ name: editLabelValue, value: editLabelValue });
        } catch (error) {
            console.error('Error updating label:', error);
            alert('Failed to update label. Please try again.');
        }
    };

    const isInputValueNotInOptions = inputValue && !options.some(option => option.name === inputValue);
    const shouldDisplayButton = (inputValue && isInputValueNotInOptions) || editLabelId;
    
    const handleDelete = ()=>{
        console.log("Deleted")
    }
    return (
        <div style={{ position: 'relative', width: 'fit-content' }}>
            <Autocomplete
                options={options}
                getOptionLabel={(option) => option.name}
                style={{ width: 200 }}
                renderInput={(params) => {
                    params.inputProps.value = selectedLabel ? '' : params.inputProps.value;
                    return (
                        <TextField
                            {...params}
                            className={className}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: selectedLabel ? (
                                    <Chip
                                        label={selectedLabel.name}
                                        style={{
                                            backgroundColor: labelColors[selectedLabel.name],
                                            color: 'white',
                                            marginRight: '-0.8rem',
                                            padding: '0px'
                                        }}
                                        // onDelete={handleDelete}
                                        size='small'
                                    />
                                ) : null,
                            }}
                        />
                    );
                }}
                renderOption={(props, option) => (
                    <li {...props}>
                        <span className="pill-badge" style={{ backgroundColor: labelColors[option.name] }}>{option.name}</span>
                    </li>
                )}
                onChange={(event, newValue) => {
                    setSelectedLabel(newValue);
                    if (onLabelChange) {
                        onLabelChange(newValue);
                    }
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                value={selectedLabel}
                freeSolo
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <span key={index} className="selected-badge" style={{ backgroundColor: labelColors[option.name] }}>
                            {option.name}
                        </span>
                    ))
                }
            />
            {shouldDisplayButton && (
                <Button
                    variant="contained"
                    size="small"
                    style={{ position: 'absolute', top: '100%', right: 0, marginTop: '0.5rem' }}
                    onClick={editLabelId ? handleEditLabel : handleAddLabel}
                    className='labelCustomize_btn'
                >
                    <CheckIcon />
                </Button>
            )}
        </div>
    );
};

export default LabelsDropdown;
import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { titleCase } from "title-case";
import Stack from "@mui/material/Stack";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Button, Box, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { jwtDecode } from "jwt-decode";

const BreadcrumbsComponent = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const pathArray = location.pathname.split("/");

  const navigate = useNavigate();
  let currentLink = "";

  const token = localStorage.getItem("accessToken");
  let role = "user";
  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.email === "sushmitha.gopari@motivitylabs.com") {
      // navigate("/admin/dashboard");
      role = "admin";
    } else {
      // navigate("/projects");
      role = "user";
    }
  }

  const breadcrumbs = pathArray
    .filter((crumbs) => crumbs !== "")
    .map((eachPath, index) => {
      currentLink += `/${eachPath}`;
      if (index === pathArray.length - 2 || index === pathArray.length - 3) {
        return (
          <Typography key={index} sx={{ color: "#000000 !important" }}>
            {titleCase(eachPath)}
          </Typography>
        );
      }

      return (
        <NavLink
          key={index}
          className="breadcrumbs-links crumb-class-name"
          to={role !== "admin"?currentLink:'/admin/dashboard'}
        >
          {titleCase(eachPath)}
        </NavLink>
      );
    });


  const handleNavigation = () => {
    // const token = localStorage.getItem("accessToken");

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.email === "sushmitha.gopari@motivitylabs.com") {
          navigate("/admin/dashboard");
        } else {
          navigate("/projects");
        }
      } catch (error) {
        console.error("Invalid token or token decoding failed", error);
      }
    } else {
      // navigate("/login");
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button
          sx={{
            minWidth: "25px",
            minHeight: "10px",
            borderRadius: "50%",
            color: "black",
          }}
          // onClick={() => { navigation('/projects') }}
          onClick={handleNavigation}
        >
          <ArrowBackIcon />
        </Button>
        <Stack spacing={1} className="my-3 pe-3">
          <Breadcrumbs
            aria-label="breadcrumb"
            className="d-flex align-items-center"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
      </Box>
    </>
  );
};

export default BreadcrumbsComponent;

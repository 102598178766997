import React, { useEffect } from "react";
import SideBar from "../../../../components/SideBar/Sidebar";
import {
  getSprintsList,
  getBacklogIssues,
  getActiveProjectData,
  getActiveSprintAssigneesLayoutData,
  setSelectedProjectBackLogId,
  selectedProjectSlug,
  getLastFiveActiveSprintInfo,
  setSkeletonStatus,
  setClearFilterAssignees
} from "../../../../common/Redux/Reducer";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { notificationSubscription } from "../../../../services/notificationSubscription";

const Index = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { Projects, assignees } = useSelector((state) => {
    return state.ProjamAppSlice;
  });
  const contributors = Projects?.selectedProject?.contributors;
  const activeSprint = Projects?.selectedProject?.ActiveSprint;
  const selectedProjectData = Projects?.selectedProject?.selectedProjectData;
  let projectId = Projects?.selectedProject?.selectedProjectData?._id
  let backlogId = Projects?.selectedProject?.selectedProjectBackLogId

  const {projectSlug}=useParams()

  useEffect(() => {
    getRequiredData()
  }, [Projects?.selectedProject?.selectedProjectBackLogId]);

  useEffect(() => {
   
    if (projectSlug) {
      // if(assignees.length){
        dispatch(setClearFilterAssignees());
     // }
     
     const getProInfo = async()=>{
      try{

      let projInfo=  await dispatch(getActiveProjectData(projectSlug));
    
     
     const {payload} = projInfo 
    
     if(!payload){
      navigate('/projects')
      return 
     }
    let projectId = payload._id
    let backlogId  =payload?.backlogs[0]
      dispatch(setSelectedProjectBackLogId({ id: payload?.backlogs[0] }))
      dispatch(selectedProjectSlug({slug:projectSlug}))
      dispatch(getLastFiveActiveSprintInfo({backlogId , projectId})) 
    
    
     }
    
    catch(error){
      navigate('/projects')
    }
  }
     getProInfo()
     
  }
 }, [projectSlug])

  useEffect(() => {
    if (activeSprint?._id) {
      dispatch(getActiveSprintAssigneesLayoutData({ activeSprintId: activeSprint?._id, payload: { contributors: contributors } }))
    }
  }, [activeSprint, contributors])

  const getRequiredData=async()=>{
    if (Projects?.selectedProject?.selectedProjectBackLogId) {
      await dispatch(setSkeletonStatus({ Skeleton: true }))
      await  dispatch(
       getSprintsList({
         backlogId: Projects?.selectedProject?.selectedProjectBackLogId, filterAssigneesArray: []
       })
     );
     await  dispatch(
       getBacklogIssues(Projects?.selectedProject?.selectedProjectBackLogId)
     );
     await  dispatch(setSkeletonStatus({ Skeleton: false }))
     //  dispatch(getLastFiveActiveSprintInfo({backlogId , projectId})) 
   } else {
     let BacklogId = localStorage.getItem("backlogId");
     if (BacklogId) {
      await   dispatch(setSkeletonStatus({ Skeleton: true }))
      await dispatch(getSprintsList({ backlogId: BacklogId, filterAssigneesArray: assignees }));
      await dispatch(getBacklogIssues(BacklogId));
      await  dispatch(setSkeletonStatus({ Skeleton: false }))
     }
   }
  }
  return (
    <div style={{ backgroundColor: '#F9F8F7' }}>
      <SideBar selectedProjectData={selectedProjectData} />
    </div>
  );
};

export default Index;

import React, { useContext, useEffect, useState} from "react";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { FiLink } from "react-icons/fi";
import axios from "axios";
import API_BASE_URL from "../../components/config/appConfig";
import { ProjectContext } from "../../containers/Dashboard";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TagOutlinedIcon from "@mui/icons-material/TagOutlined";
import { HiMenuAlt2 } from "react-icons/hi";
import { TbArrowBearRight2 } from "react-icons/tb";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { colors } from "../../utilities/colors";
import TaskWorkFlowModalPage from "../../components/Modal/TaskWorkFlow";
import CreateBacklogIssue from "../Create_Backlogissue";
import { Grid, Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import PestControlIcon from '@mui/icons-material/PestControl';
import TaskIcon from '@mui/icons-material/Task';
import BackgroundLetterAvatarsLogo from "../AvatarLogo";
import styled from 'styled-components';
import moment from "moment";
import { getActiveProjectData, setSkeletonStatus } from "../../common/Redux/Reducer";
import { NormalSkeleton } from "../../common/Skeleton";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useParams , useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';

const StyledCellContainer = styled.div`
  align-items: center;
  padding: 9px 20px;
`;

const AllIssuesTable = ({ searchTerm, listIssues = [], sprintId, issues, setIssues, setSprintName, sprintName }) => {
  const [selected, setSelected] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  // const [issues, setIssues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  //const { backlogId, setBacklogId } = useContext(ProjectContext);
  // const [ backlogId, setBacklogId ] = useState()
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [listModalOpen, setListModalOpen] = useState(false);
  const [listWorkFlowModalData, setListWorkFlowModalData] = useState({});
  const [isCreateNewBackIssuse, setIsCreateNewBackIssue] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [issueStatusList, setIssueStatusList] = useState([])
  const [selectedSprint, setSelectedSprint] = useState(sprintId || null);

  const { Projects, Skeleton } = useSelector((state) => state.ProjamAppSlice);
  const activeSprint = Projects?.selectedProject?.ActiveSprint;
  let backlogId = Projects?.selectedProject?.selectedProjectBackLogId;
  const dispatch = useDispatch()
  const {projectSlug} = useParams();
  const navigate= useNavigate()

  const onCreateBackLogIssue = () => {
    setIsCreateNewBackIssue(true);
  };

  // useEffect(() => {
  //   const fetchIssues = async () => {
  //     if (!backlogId) {
  //       // console.error("No backlog ID found");
  //       return;
  //     }
  //     try {
  //       const apiUrl = sprintId && sprintId !== "123"
  //         ? `${API_BASE_URL}/issues?backlogId=${backlogId}&sprintId=${sprintId}`
  //         : `${API_BASE_URL}/issues?backlogId=${backlogId}`;
          
  //       const response = await axios.get(apiUrl);
  //       setIssues(response.data);
  //       setFilteredIssues(response.data);
  //     } catch (error) {
  //       setError(error.message);
  //     } finally { 
  //       setLoading(false);
  //     }
  //   };

  //   fetchIssues();
  // }, [backlogId, sprintId, setIssues, setSprintName, listModalOpen]); 

  // useEffect(() => {
  //   const storedBacklogId = localStorage.getItem("backlogId");
  //   if (storedBacklogId) {
  //     setBacklogId(storedBacklogId);
  //   } else {
  //     console.error("No backlog ID found in local storage");
  //   }
  // }, []);

  useEffect(() => {
    const fetchIssues = async () => {
      if (!backlogId) {
        return;
      }
      try {
        const response = await axios.get(
          `${API_BASE_URL}/issues?backlogId=${backlogId}`
        );
        const issuesData = response.data;
        const filteredIssues = sprintId && sprintId !== "123"
          ? issuesData.filter(issue => issue.sprintdetails?.name === sprintName)
          : issuesData;
  
        setIssues(filteredIssues);
        setFilteredIssues(filteredIssues);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
        dispatch(setSkeletonStatus({ Skeleton: false }));
      }
    };
  
    fetchIssues();
  }, [backlogId, sprintId, sprintName, listModalOpen]);

  useEffect(() => {
    if (selectedSprint) {
      const filteredBySprint = issues.filter(issue => 
        issue.sprintdetails?.name === selectedSprint
      );
      setFilteredIssues(filteredBySprint);
    } else {
      setFilteredIssues(issues);
    }
  }, [selectedSprint, issues]);

  useEffect(() => {
    if (searchTerm) {
      const lowercasedTerm = searchTerm.toLowerCase();
      const filtered = issues.filter(
        (issue) =>
          (issue.name && issue.name.toLowerCase().includes(lowercasedTerm)) ||
          (issue.summary &&
            issue.summary.toLowerCase().includes(lowercasedTerm)) ||
          (issue.status && issue.status.toLowerCase().includes(lowercasedTerm))
      );
      setFilteredIssues(filtered);
    } else {
      setFilteredIssues(issues);
    }
  }, [searchTerm, issues]);

  
  const handleClick = (event, id, rowData) => {
   // console.log("handleClick", rowData);
    setSelectedIssue(rowData);
    if (event && event.target && event.target.tagName === "INPUT") {
      const selectedIndex = selected.indexOf(id);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }

      setSelected(newSelected);
    }

    onChangeModalStatus(rowData);
  };

  const onChangeModalStatus = (data) => {
    setListWorkFlowModalData(data);
    setListModalOpen(!listModalOpen);
  };

  const handleRowEnter = (params) => {
    setHoveredRow(params.id);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  // useEffect(() => {
  //   getProjectDetails()
  // }, [projectSlug])

  const [projectDetails, setProjectDetails] = useState();
  const getProjectDetails = async () => {
   // const projetId = localStorage.getItem('projectId');
  // const token = localStorage.getItem("accessToken");
   
    try {
      let projectInfo= await dispatch(getActiveProjectData(projectSlug))
      
      const {payload}= projectInfo
      if(!payload){
        navigate('/projects')
      }
          setProjectDetails(payload)
          setIssueStatusList(payload.IssueStatus)
          // setBacklogId(payload?.backlogs[0])
        }
      
     catch (error) {
      console.log(error.message)
      navigate('/projects')
      //throw new Error(error.message);
    }
  };


 
  const columns = [
    {
      field: "issueType",
      headerName: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontSize: "13px",
          }}
        >
          Type
        </Box>
      ),
      width: 120,
      renderCell: (params) => {
        const iconMap = {
          Task: <TaskIcon style={{ color: '#0953C3', fontSize: '15px' }} />,
          Story: <BookmarkIcon style={{ color: '#F29F05', fontSize: '15px' }} />,
          Bug: <PestControlIcon style={{ color: 'red', fontSize: '15px' }} />
        };

        return (
          <Box
            className='issue-type-icon-container'
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              padding: '0px 6px',
            }}
          >
            {iconMap[params.value] || null}
            <span style={{ fontSize: '14px', fontWeight: '500' }}>{params.value || " - "}</span>
          </Box>
        );
      },
    },
    {
      field: "key",
      headerName: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontSize: "13px",
          }}
        >
          <TagOutlinedIcon sx={{ fontSize: "16px" }} />
          Key
        </Box>
      ),
      width: 160,
      renderCell: (params) => {
        const rowData = params.row;
        const isHovered = hoveredRow === params.id;

        return (
          <Box
            onMouseEnter={() => handleRowEnter(params)}
            onMouseLeave={handleRowLeave}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              textDecoration: isHovered ? "underline" : "none",
              cursor: "pointer",
            }}
            onClick={() => handleClick(params, params.id, rowData)}
          >
            <a
              href={`#${rowData.name}`}
              style={{
                color: "inherit",
                display: "inline-block",
                verticalAlign: "middle",
              }}
            >
              {rowData.name}
            </a>
            {isHovered && (
              <FiLink
                style={{
                  marginLeft: "5px",
                  verticalAlign: "middle",
                }}
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "summary",
      headerName: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontSize: "13px",
          }}
        >
          <HiMenuAlt2 sx={{ fontSize: "16px" }} />
          Summary
        </Box>
      ),
      width: 300,
      editable: true,
    },
    {
      field: 'status',
      headerName: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            fontSize: '13px',
          }}
        >
          Status
        </Box>
      ),
      width: 175,
      renderCell: (params) => {
        let badgeColor;
        switch (params.value) {
          case 'To Do':
            badgeColor = 'secondary';
            break;
          case 'In Progress':
            badgeColor = 'info';
            break;
          case 'In Review':
            badgeColor = 'warning';
            break;
          case 'QA':
            badgeColor = 'danger';
            break;
          case 'Done':
            badgeColor = 'success';
            break;
          // case 'Feedback/Hold':
          //   badgeColor = 'warning';
          //   break;
          case 'Production Ready':
            badgeColor = 'dark';
            break;
          default:
            badgeColor = 'primary';
        }
        return (
          <Box
            className={`badge rounded-pill bg-${badgeColor}`}
            style={{
              fontSize: "0px !important",
              padding: "3px 6px",
              color: 'white',
              fontWeight: "200",
            }}
          >
            {params.value}
          </Box>
        );
      },
    },
    {
      field: "priority",
      headerName: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontSize: "13px",
          }}
        >
          Priority
        </Box>
      ),
      width: 120,
      renderCell: (params) => {
        const iconMap = {
          Urgent: <NotificationsActiveIcon className='issue-type-icon' sx={{ color: '#F29F05' }} />,
          High: <KeyboardDoubleArrowUpIcon style={{ color: 'red', fontSize: '20px' }} />,
          Medium: <DragHandleIcon style={{ color: '#0953C3', fontSize: '20px' }} />,
          Low: <KeyboardDoubleArrowDownIcon style={{ color: '#0953C3', fontSize: '20px' }} />
        };

        return (
          <Box
            className='issue-type-icon-container'
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
              padding: '0px 6px',
            }}
          >
            <Tooltip title={params.value || "Urgent"}>
            <span style={{ fontSize: '14px', fontWeight: '500' }}>{iconMap[params.value] || null}</span> 
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "sprint",
      headerName: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontSize: "13px",
          }}
        >
          <TbArrowBearRight2 sx={{ fontSize: "16px" }} />
          Sprint
        </Box>
      ),
      width: 130,
      renderCell: (params) => {
        const sprintName = params.row.sprintdetails?.name;
        return sprintName ? sprintName : "Backlog Issue";
      },
      icon: (
        <FiLink
          style={{
            marginLeft: "5px",
            verticalAlign: "middle",
          }}
        />
      ),
    },
    {
      field: "assignee",
      headerName: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontSize: "13px",
          }}
        >
          <AlternateEmailIcon sx={{ fontSize: "16px" }} />
          Assignee
        </Box>
      ),
      width: 160,
      renderCell: (params) => {
        const employeeName = params.row?.assignee && params.row.assignee[0]
          ? params.row.assignee[0].employeeName
          : "";
        return (
          <StyledCellContainer>
            <BackgroundLetterAvatarsLogo
              LogoTitle={employeeName}
              RequiredFontSize="13px !important"
            />
          </StyledCellContainer>
        );
      },
    },
    {
      field: "createdAt",
      headerName: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontSize: "13px",
          }}
        >
          <CalendarMonthIcon sx={{ fontSize: "16px" }} />
          Created
        </Box>
      ),
      width: 150,
      renderCell: (params) => {
        const { createdAt } = params.row;
        const formattedDate = moment(createdAt).format('DD MMM YYYY');
        return (
          <div>
            {formattedDate}
          </div>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            fontSize: "13px",
          }}
        >
          <CalendarMonthIcon sx={{ fontSize: "16px" }} />
          Updated
        </Box>
      ),
      width: 150,
      renderCell: (params) => {
        const { updatedAt } = params.row;
        const formattedDate = moment(updatedAt).format('DD MMM YYYY');
        return (
          <div>
            {formattedDate}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {!Skeleton ? <>
          <Grid item xs={12} md={12} >
          <Box className="ps-3 pe-3">
            <DataGrid
              rows={filteredIssues.map((issue) => ({
                id: issue._id,
                issueType: issue.issueType,
                ...issue,
              }))}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              loading={loading}
              error={error ? error.message : undefined}
              onSelectionModelChange={(newSelection) => {
                setSelected(newSelection.selectionModel);
              }}
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  backgroundColor: "#ebeceb",
                },
                "& .MuiCheckbox-colorPrimary.Mui-checked": {
                  color: colors.text_green,
                },
                "& .MuiDataGrid-row.Mui-selected": {
                  backgroundColor: "#daf0cb",
                },
                "& .MuiDataGrid-row.Mui-selected:hover": {
                  backgroundColor: "#bee5a4",
                },
                "& .MuiTablePagination-root": {
                  display: "none !important",
                },
              }}
            />
            <Box sx={{ display: "flex", alignItems: "center", mt: 2, mb: 3 }}>
              {!isCreateNewBackIssuse ? (
                <Typography
                  sx={{ fontSize: "16px", cursor: "pointer" }}
                  onClick={() => onCreateBackLogIssue()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-plus"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                  </svg>
                  Create issue
                </Typography>
              ) : (
                <CreateBacklogIssue
                  isCreateNewBackIssuse={isCreateNewBackIssuse}
                  setIsCreateNewBackIssue={setIsCreateNewBackIssue}
                  listIssues={listIssues}
                />
              )}
            </Box>
            </Box>
            {/* </Grid> */}
          </Grid>
          {/* </TableContainer>
        </Paper> */}
        </> : <div className="py-4"> <NormalSkeleton variant='rectangular' height={400} width="100%" /></div>}
        {listModalOpen && (
            <TaskWorkFlowModalPage
              open={listModalOpen}
              activeSprintName={activeSprint?.name}
              handleChangeModalOpen={() => setListModalOpen(!listModalOpen)}
              // workFlowModalTaskData={listWorkFlowModalData}
              selectedissueId={listWorkFlowModalData?._id}
              setWorkFlowModalData={setListWorkFlowModalData}
              projectDetails={projectDetails}
              issueStatusList={issueStatusList}
            />
          )}
    </>
  );
};

export default AllIssuesTable;

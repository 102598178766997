import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  ButtonGroup,
  Typography,
  Button,
  Menu,
  MenuItem,
  Chip,
  IconButton,
  ListItemText,
  Grid, Select
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import CloseIcon from '@mui/icons-material/Close';
import BreadcrumbsComponent from '../BreadcrumbsComponent';
import SearchTextField from '../SearchTextField';
import AddPeopleToProject from '../../containers/Dashboard/ProjectPages/AddPeopleToProject';
import RemoveUserFromProject from '../RemoveUserFromProject';
import { NormalSkeleton } from '../../common/Skeleton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BackgroundLetterAvatarsLogo from '../AvatarLogo';
import axios from 'axios';
import API_BASE_URL from '../config/appConfig';
import './projectaccesscontrol.scss';
// import { useParams, useNavigate } from 'react-router-dom';
// import { issueStatus } from '../../utilities/Json';

const ProjectAccessControl = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const { Projects, Skeleton } = useSelector((state) => state.ProjamAppSlice);
  const [openAddPeopleModel, setOpenAddPeopleModel] = useState(false);
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);


  // let backlogId = Projects?.selectedProject?.selectedProjectBackLogId
  let projectId = Projects?.selectedProject?.selectedProjectData?._id
  // let ProjectName = Projects?.selectedProject?.selectedProjectData?.title;
  let Projectslug = Projects?.selectedProject?.projectSlug
  // console.log("ProjectName",ProjectName)
  //ssssssssconst { projectSlug } = useParams()
  // const navigate = useNavigate();
  // const dispatch = useDispatch()

  const [contributors, setContributors] = useState([]);

  const issueStatusList = Projects?.selectedProject?.selectedProjectData?.IssueStatus || [];


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setOpenRemoveDialog(false);
    setUserToRemove(null);
  };

  useEffect(() => {
    const applyFilters = () => {
      let filtered = contributors;

      if (searchTerm) {
        const lowercasedTerm = searchTerm.toLowerCase();
        filtered = filtered.filter((eachContributor) =>
          eachContributor.employeeName.toLowerCase().includes(lowercasedTerm)
        );
      }

      if (selectedRoles.length > 0) {
        filtered = filtered.filter((eachContributor) =>
          selectedRoles.includes(eachContributor.role)
        );
      }

      setFilteredData(filtered);
    };

    applyFilters();
  }, [searchTerm, selectedRoles, contributors]);

  // useEffect(() => {
  // }, [openAddPeopleModel]);
  useEffect(() => {
    if (Projects?.selectedProject?.contributors) {
      setContributors(Projects.selectedProject.contributors);
    }
  }, [Projects?.selectedProject?.contributors]);

  const handleToggleRole = (role) => {
    const currentIndex = selectedRoles.indexOf(role);
    const newSelectedRoles = [...selectedRoles];

    if (currentIndex === -1) {
      newSelectedRoles.push(role);
    } else {
      newSelectedRoles.splice(currentIndex, 1);
    }

    setSelectedRoles(newSelectedRoles);
    handleClose();
  };

  const handleRemoveRole = (role) => {
    setSelectedRoles((prevSelectedRoles) =>
      prevSelectedRoles.filter((selectedRole) => selectedRole !== role)
    );
  };

  const handleConfirmRemove = async () => {
    if (userToRemove) {
      try {
        await axios.post(`${API_BASE_URL}/projects/removeMember?projectid=${projectId}`, {
          employeeId: userToRemove.employeeId,
        });
        setContributors((prevContributors) =>
          prevContributors.filter((contributor) => contributor.employeeId !== userToRemove.employeeId)
        );
        //getProjectBySlug();
      } catch (error) {
        console.log(error);
      } finally {
        handleCloseDialog();
      }
    }
  };

  const formatSelectedRoles = () => {
    if (selectedRoles.length === 0) return 'Roles';
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {selectedRoles.map((role) => (
          <Chip
            key={role}
            label={role}
            onDelete={() => handleRemoveRole(role)}
            deleteIcon={<CloseIcon />}
            sx={{
              margin: '2px',
              backgroundColor: '#E3E4E9',
              borderRadius: '15px',
              border: '1px solid #60A630',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          />
        ))}
      </Box>
    );
  };

  const handleSearch = (text) => {
    setSearchTerm(text);
  };

  const handleRemoveUserClick = (contributor) => {
    setUserToRemove(contributor);
    setOpenRemoveDialog(true);
  };

  // const roles = [
  //   'Project Manager',
  //   'Scrum Master',
  //   'Project Lead',
  //   'Developer',
  //   'QA Tester',
  //   'DevOp'
  // ];

  useEffect(() => {
    getRoles()
  }, [])

  const [roles, setRoles] = useState([])
  const getRoles = async () => {
    try {
      let res = await axios.get(`${API_BASE_URL}/accessroles`)
      // console.log("Roles res", res.data)
      setRoles(res.data)
    } catch (err) {
      console.log(err)
    }

  }

  const [userRoles, setUserRoles] = useState({});

  const updateRoleApi = async (userDetails, newRole) => {
    const rolesChanges =
    {
      memberEmail: userDetails.employeeEmail,
      project: Projectslug,
      role: newRole,
    };
    try {
      const response = await fetch(`${API_BASE_URL}/projects/updateMemberRole`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(rolesChanges),
      });
      const data = await response.json();
      console.log('Role updated:', data);
    } catch (error) {
      console.error('Error updating role:', error);
    }
  };

  // Handle role change
  const handleRoleChange = (userDetails, newRole) => {
    // console.log("userDetails", userDetails, newRole)
    setUserRoles((prevRoles) => ({
      ...prevRoles,
      [userDetails.employeeId]: newRole,
    }));
    updateRoleApi(userDetails, newRole);
  };

  const columns = [
    {
      field: 'employeeName',
      headerName: 'Name',
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <Box display="flex" alignItems="center" sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
          <BackgroundLetterAvatarsLogo
            LogoTitle={params.row.employeeName}
            RequiredFontSize="10px !important"
          />
          <Typography variant="body1" className="ms-2">
            {params.row.employeeName}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'employeeEmail',
      headerName: 'Email',
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <Typography
          sx={{
            paddingTop: '15px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>
          {params.row.employeeEmail}
        </Typography>
      ),
    },
    // {
    //   field: 'role',
    //   headerName: 'Role',
    //   flex: 1,
    //   editable: true,
    //   renderCell: (params) => (
    //     <Typography sx={{ paddingTop: '15px' }}>
    //       {params.row.role}
    //     </Typography>
    //   ),
    // },
    {
      field: 'role',
      headerName: 'Role',
      flex: 1,
      editable: true,
      renderCell: (params) => {
        const selectedRole = userRoles[params.row.employeeId] || params.row.role;
        return (
          <Select
            value={selectedRole}
            onChange={(e) => handleRoleChange(params.row, e.target.value)}
            variant="outlined"
            sx={{
              width: '100%',
              border: '1px solid black',
              '& .MuiSelect-select': {
                padding: '4px 8px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          >
            {roles.map((roleObj) => (
              <MenuItem key={roleObj._id} value={roleObj.role}>
                {roleObj.role}
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      renderCell: (params) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingTop: '15px',
            paddingBottom: '15px',
          }}
        >
          <IconButton
            onClick={() => handleRemoveUserClick(params.row)}
            sx={{
              display: 'flex',
              alignItems: 'center',
              '&:hover': {
                borderRadius: 5,
                border: '1px solid #E3E4E9',
              },
              padding: 0,
            }}
          >
            <PersonRemoveIcon sx={{ fontSize: 45, color: 'red' }} />
            <Typography
              sx={{
                marginLeft: 1,
                textDecoration: 'underline',
                textAlign: 'center',
              }}
            >
              Remove
            </Typography>
          </IconButton>
        </Box>
      ),
    },
  ];

  const broadIssuesColumns = [
    { field: 'issueStatus', headerName: 'Issue Status', flex: 2 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        <>
          <IconButton
            sx={{ backgroundColor: 'transparent', color: '#F29F05' }}
            aria-label="edit"
          // onClick={() => handleEdit(params.row.id)}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            sx={{ backgroundColor: 'transparent', color: 'red' }}
            aria-label="delete"
          // onClick={() => handleDelete(params.row.id)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  const broadIssuesRows = issueStatusList?.map((issue, index) => ({
    id: issue.issueId || `Issue-${index + 1}`,
    issueStatus: issue.title,
  }));

  return (
    <Box className="project-settings-page">
      <div className="ps-2 ">
        {!Skeleton ? <BreadcrumbsComponent /> : <NormalSkeleton width={200} extraStyles="my-2" />}
      </div>
      <Box className={`px-3 d-flex justify-content-between align-items-center ${Skeleton ? 'ps-2' : ''}`}>
        <Typography sx={{ fontSize: '18px !important', fontWeight: 600, fontFamily: 'roboto' }}>
          Access
        </Typography>
        <Box className="d-flex justify-content-end">
          <ButtonGroup>
            <Button
              sx={{
                backgroundColor: `#60A630 !important`,
                border: '0px !important',
                color: '#fff !important',
                borderRadius: '5px  !important',
                textTransform: 'none !important',
              }}
              className="mx-1"
              onClick={() => setOpenAddPeopleModel(true)}
            >
              Add people
            </Button>
            <Button
              sx={{
                backgroundColor: '#E3E4E9 !important',
                border: '0px !important',
                color: '#000000 !important',
                borderRadius: '5px  !important',
                textTransform: 'none !important',
              }}
              className="mx-1"
            >
              Manage roles
            </Button>
          </ButtonGroup>
        </Box>
      </Box>
      <Typography className="ps-3 pe-3 my-1" variant="h6" gutterBottom>
        Project access
      </Typography>
      <Box className="d-flex justify-content-between align-items-center ps-2 pe-3 my-3">
        <Box className="d-flex align-items-center">
          {Skeleton ? (
            <NormalSkeleton variant="rectangular" width={150} height={35} />
          ) : (
            <SearchTextField searchTerm={searchTerm} handleSearch={handleSearch} />
          )}
          <Button
            onClick={handleClick}
            sx={{
              backgroundColor: '#E3E4E9 !important',
              border: '0px !important',
              color: '#000000 !important',
              borderRadius: '5px  !important',
              textTransform: 'none !important',
            }}
            className="mx-1"
            endIcon={<ArrowDropDownIcon />}
          >
            {formatSelectedRoles()}
          </Button>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            {['Project Manager ', 'Scrum Master', 'Project Lead', 'Administration', 'Developer', 'QA Tester', 'DevOps'].map((role) => (
              <MenuItem key={role} onClick={() => handleToggleRole(role)}>
                <ListItemText primary={role} />
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Box>
      {Skeleton ? (
        <NormalSkeleton variant="rectangular" width="100%" height={400} />
      ) : (
        <Box className="ps-3 pe-3 project-members-table-cls">
          <DataGrid
            rows={filteredData}
            columns={columns}
            autoHeight
            disableSelectionOnClick
            pageSize={5}
            //rowsPerPageOptions={[5,10,15]}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            getRowId={(row) => row.employeeId}
          />
        </Box>
      )}
      <Box className="ps-3 pe-3 my-3 mb-3" sx={{ width: '100%' }}>
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          Board Settings
        </Typography>

        {Skeleton ? (
          <NormalSkeleton variant="rectangular" width="100%" height={400} />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6} sx={{ paddingLeft: '10px !important' }}>
              <Box className="ps-3 pe-3">
                <DataGrid
                  rows={broadIssuesRows}
                  columns={broadIssuesColumns}
                  //rowsPerPageOptions={[5, 10, 15]}
                  // pageSizeOptions={[5, 10, 25]}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 25, page: 0 },
                    },
                  }}
                  autoHeight
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="ps-3 pe-3">

              </Box>
            </Grid>
          </Grid>
        )}

      </Box>
      {openAddPeopleModel && (<AddPeopleToProject
        open={openAddPeopleModel}
        setOpenAddPeopleModel={setOpenAddPeopleModel}
      />)}

      {openRemoveDialog && (<RemoveUserFromProject
        open={openRemoveDialog}
        handleClose={handleCloseDialog}
        userToRemove={userToRemove}
        handleConfirmRemove={handleConfirmRemove}
      />)}

    </Box>
  );
};

export default ProjectAccessControl;


import React, { useState, useEffect, useCallback } from "react";
import BreadcrumbsComponent from "../../../../components/BreadcrumbsComponent";
import { Box, Typography, Button, Tooltip } from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ProjectIssues from "../../../../components/ProjectIssues";
// import BacklogDetailsModel from "./BacklogDetailsModel";
import AddPeopleToProject from "../AddPeopleToProject";
import BacklogHeader from "../../../../components/BaclogHeader";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation, useParams } from 'react-router-dom'
import { NormalSkeleton } from "../../../../common/Skeleton";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { updateIssue, getSprintsList, getBacklogIssues, getActiveProjectData } from "../../../../common/Redux/Reducer";
import axios from "axios";
import API_BASE_URL from "../../../../components/config/appConfig";
import TaskWorkFlowModalPage from "../../../../components/Modal/TaskWorkFlow";
import BoltIcon from '@mui/icons-material/Bolt';
import { useSearchParams } from "react-router-dom";
import { object } from "prop-types";

const BacklogPage = () => {
  const btnGroupBtnStyles = {
    backgroundColor: "transparent !important",
    border: "0px !important",
    color: "#000000 !important",
  };
  const [searchParams ,setSearchparams] = useSearchParams();
  let urlQueryObj = Object.fromEntries([...searchParams]);        
  const [openGallery, setOpenGallery] = useState(true);
  const [SprintIndex, setSprintIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [openselectedIssue, setOpenSelectedIssue] = useState(false);
  const [openselectedSprintIssue, setOpenSelectedSprintIssue] = useState(urlQueryObj?.selectedIssue? urlQueryObj?.selectedIssue: false);
  const [selectedIssueName, setSelectedIssueName]=useState(urlQueryObj?.selectedIssue || null);
 


  const [openAddPeopleModel, setOpenAddPeopleModel] = useState(false);
  const { Projects, Skeleton, assignees } = useSelector((state) => state.ProjamAppSlice);
  const sprintDataList = Projects?.selectedProject?.sprintsList;
  const activeSprint = Projects?.selectedProject?.ActiveSprint;
  const IssuesInBacklog = Projects?.selectedProject?.backlogIssuesList;
  const projectEpicData = Projects?.selectedProject?.selectedProjectEpicsData;

  const [searchTerm, setSearchTerm] = useState("");
  const [isActiveSprintExist, setIsActiveSprintExist] = useState(false)
  const [projectIssuesData, setSprintsDataList] = useState([]);
  const [selectedTaskType, setSelectedTaskType] = useState("");
  const [selectPriorityType, setSelectPriorityType] = useState('');
  const [workFlowModalData, setWorkFlowModalData] = useState({});
  const [ShowCopied, setShowCopied] = useState(false);
  // const location = useLocation()
  const dispatch = useDispatch();
  // const { projectSlug } = useParams()
  const [selectedEpic, setSelectedEpic] = useState('')
  const [selectedProjectEpicsData, setselectedProjectEpicsData] = useState([])

  const handleSearch = (text) => {
    setSearchTerm(text);
  };

  const handleClick = (index) => {
    setSprintIndex(index);
    setOpenGallery(!openGallery);
  };
  let BacklogId = Projects?.selectedProject?.selectedProjectBackLogId;
  let ProjectName = Projects?.selectedProject?.selectedProjectData?.title
  // let projectId = Projects?.selectedProject?.selectedProjectData?._id

  useEffect(() => {
    if (sprintDataList && sprintDataList?.length > 0) {
      setSprintsDataList(sprintDataList)
      checkForActiveSprint()
    }
  }, [sprintDataList])

  // useEffect(()=>{
  // let projectData = dispatch(getActiveProjectData(projectSlug))
  // },[openAddPeopleModel])
  useEffect(() => {
    if (projectEpicData) {
      const updatedProjectEpicData = projectEpicData?.map((eachData) => ({ ...eachData, icon: <span className="epic-icon-cls"><BoltIcon sx={{ color: '#fff', height: "0.7rem", fontSize: '1rem' }} /></span> }))
      setselectedProjectEpicsData(updatedProjectEpicData || [])
    }
  }, [projectEpicData])

  const handleSprintIssueClick = (issue) => {
    // console.log(issue, "issue issue")
    setSelectedIssue(issue);
    setOpenSelectedSprintIssue(true);
    setWorkFlowModalData(issue);
    setSearchparams({selectedIssue:issue?.name})
    
  };

  const handleCopyUrl = () => {
    try {
      navigator.clipboard.writeText(window.location.href)
      setShowCopied(true)
    }
    catch (error) {

    }
  }

  const handleIssueClick = (issue) => {
    setSelectedIssue(issue);
    setOpenSelectedIssue(true);
    setWorkFlowModalData(issue) 
    setSearchparams({selectedIssue:issue?.name})
  };

  const handleCloseClick = () => {
    setSelectedIssue(null);
    setOpenSelectedIssue(false);
    setOpenSelectedSprintIssue(false);
  };


  const handleStatuUpdate = (value) => {
    if (value) {
      setSelectedTaskType(value);
    } else {
      setSelectedTaskType("");
    }
  };
  const handlePriorityUpdate = (priority) => {
    setSelectPriorityType(priority)
  }

  const onDragEnd = async (result) => {
    if (!result?.destination) return;

    const { source, destination, draggableId } = result;
    try {
      if (destination?.droppableId !== source?.droppableId) {
        if (destination.droppableId === "backlog") {
          await dispatch(updateIssue({ issueId: draggableId, payload: { sprint: null } }));
        } else {
          let response = await axios.put(`${API_BASE_URL}/issues/moveto/sprint/${destination.droppableId}`,
            { selectedIssues: [draggableId] }
          )
        }
        await dispatch(getSprintsList({ backlogId: BacklogId, filterAssigneesArray: assignees }));
        await dispatch(getBacklogIssues(BacklogId));
      }
      handleCloseClick()

    } catch (error) {
      console.error("An error occurred while updating the issue or fetching data:", error);
    }

  }
  const checkForActiveSprint = async () => {
    try {

      let activeSprint = sprintDataList.filter((sprint) => {
        return sprint.active === true
      })
      let remainingSprints = sprintDataList.filter((sprint) => {
        return sprint.active !== true
      })
      if (activeSprint && activeSprint.length > 0) {
        setSprintsDataList([...activeSprint, ...remainingSprints])
        setIsActiveSprintExist(true)
      }
    }
    catch (error) {
      console.log(error, 'error')
    }
  }
  const handleEpicData = (data) => {
    // console.log(data, "setSelectedEpic")
    if (data) {
      setSelectedEpic(data);
    } else {
      setSelectedEpic(data);
    }
  }
  // console.log(selectedProjectEpicsData, projectEpicData, "selectedProjectEpicsData selectedProjectEpicsData projectEpicData")
  return (
    <div className="backlog-page-main-container">
      <div className="ps-2"> {!Skeleton ? <BreadcrumbsComponent /> : <NormalSkeleton width={200} extraStyles="my-2" />}</div>
      <Box className={`px-3 d-flex justify-content-between align-items-center ${Skeleton ? 'ps-2' : ''}`}>
        {!Skeleton ? <Typography
          sx={{ fontSize: 20, fontWeight: 600, fontFamily: "roboto" }}
        >
          Backlog
        </Typography> : <NormalSkeleton width={200} extraStyles="my-2" />}
        <ButtonGroup
          variant="contained"
          aria-label="outlined button group"
          className="shadow-none"
        >
          <Tooltip title={ShowCopied ? "url Copied" : "click to copy url"}>
            <Button sx={btnGroupBtnStyles} className="px-0 mx-1" onClick={() => handleCopyUrl()}>
              <ShareOutlinedIcon />
            </Button>
          </Tooltip>
          <Button
            sx={{
              backgroundColor: "#F4F5F7 !important",
              border: "0px !important",
              color: "#000000 !important",
              borderRadius: "5px  !important",
            }}
            className="px-0 mx-1"
          >
            <MoreHorizIcon />
          </Button>
        </ButtonGroup>
      </Box>
      <BacklogHeader
        setOpenAddPeopleModel={setOpenAddPeopleModel}
        searchTerm={searchTerm}
        handleSearch={handleSearch}
        handlePriorityUpdate={handlePriorityUpdate}
        handleStatuUpdate={handleStatuUpdate}
        selectedTaskType={selectedTaskType}
        selectPriorityType={selectPriorityType}
        EpicsData={selectedProjectEpicsData}
        handleEpicData={handleEpicData}
        selectedEpic={selectedEpic}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        {!Skeleton ? (
          <>
            {!loading &&
              projectIssuesData && projectIssuesData?.length > 0 &&
              projectIssuesData?.map((sprint, index) => (
                <Droppable key={sprint?._id} droppableId={sprint?._id || `sprint-${index}`} direction="vertical">
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <ProjectIssues
                          key={sprint?._id}
                          isActiveSprintExist={isActiveSprintExist}
                          setIsActiveSprintExist={setIsActiveSprintExist}
                          openGallery={openGallery}
                          onClick={(index) => handleClick(index)}
                          name={sprint?.name}
                          issues={sprint?.issues}
                          setSelectedIssue={sprint?.issues}
                          index={index}
                          sprintData={sprint}
                          SprintIndex={SprintIndex}
                          setSprintIndex={setSprintIndex}
                          sprint={sprint?._id}
                          handleSprintIssueClick={handleSprintIssueClick}
                          searchTerm={searchTerm}
                          AllsprintDetails={projectIssuesData}
                          setOpenSelectedSprintIssue={setOpenSelectedSprintIssue}
                          IssuecountObj={sprint?.IssuecountObj}
                          activeSprint={activeSprint}
                          selectedIssueType={selectedTaskType}
                          selectPriorityType={selectPriorityType}
                          selectedEpic={selectedEpic}
                        />
                        {/* {(openselectedSprintIssue && selectedIssue?.sprint === sprint?._id) && (
                    <BacklogDetailsModel
                      selectedIssue={selectedIssue}
                      handleCloseClick={handleCloseClick}
                      handleSprintIssueClick={handleSprintIssueClick}
                      setOpenSelectedSprintIssue={setOpenSelectedSprintIssue}
                    />
                  )} */}
                      </Box>
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ))}
            {!loading && (
              <Droppable droppableId="backlog" direction="vertical">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <ProjectIssues
                        openGallery={openGallery}
                        onClick={handleClick}
                        name={"backlog"}
                        issues={IssuesInBacklog}
                        handleIssueClick={handleIssueClick}
                        searchTerm={searchTerm}
                        AllsprintDetails={projectIssuesData}
                        selectedIssueType={selectedTaskType}
                        selectPriorityType={selectPriorityType}
                        selectedEpic={selectedEpic}
                      />

                      {/* {openselectedIssue && (
                <BacklogDetailsModel
                  selectedIssue={selectedIssue}
                  handleSprintIssueClick={handleSprintIssueClick}
                  handleCloseClick={handleCloseClick}
                />
              )} */}

                      {openAddPeopleModel && (
                        <AddPeopleToProject
                          open={openAddPeopleModel}
                          ProjectName={ProjectName}
                          setOpenAddPeopleModel={setOpenAddPeopleModel}
                        />
                      )}
                    </Box>
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            )}
          </>
        ) : (
          <div className="py-4">
            <NormalSkeleton variant="rectangular" height={300} width="100%" />
          </div>
        )}
      </DragDropContext>

      {openselectedSprintIssue &&
        <TaskWorkFlowModalPage
          open={openselectedSprintIssue}
          handleChangeModalOpen={() => setOpenSelectedSprintIssue(!openselectedSprintIssue)}
          selectedissueId={workFlowModalData?._id}
          issueName={selectedIssueName}   
        />
      }
      {openselectedIssue &&
        <TaskWorkFlowModalPage
          open={openselectedIssue}
          handleChangeModalOpen={() => setOpenSelectedIssue(!openselectedIssue)}
          selectedissueId={workFlowModalData?._id}
          issueName={selectedIssueName}   
        />
      }

    </div>
  );
};

export default BacklogPage;

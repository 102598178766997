import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./containers/Home/index";
import LandingPage from "./containers/Home/LandingPage";
import Login from "./containers/Home/Login";
import Dashboard from "./containers/Dashboard/index";
import ProjectsPage from "./containers/Dashboard/ProjectPages/LandingProjectsPage";
import ProjectDetailsPage from "./containers/Dashboard/ProjectPages/ProjectDetailsPage/index";
import ActiveSprintPage from "./containers/Dashboard/ProjectPages/ProjectDetailsPage/ActiveSprintPage";
import BacklogPage from "./containers/Dashboard/ProjectPages/ProjectDetailsPage/BacklogPage";
import NotFoundPage from "./containers/NotFound/index";
import Register from "./containers/Home/Register";
import Protected from "./common/protect/Protected";
import RoleProtected from "./common/protect/RoleProtected";
import IssuesList from "./components/List/IssuesList";
import Reports from "./Reports";
import ProjectSettings from "./containers/Dashboard/ProjectPages/ProjectDetailsPage/ProjectSettings";
import Admin from "./containers/Dashboard/AdminPages";
import ViewReports from "./containers/ProjectsReports";
import Profile from './containers/Home/Profile';
import ForgotPassword from "./containers/Home/ForgotPassword";
import axios from 'axios';
import API_BASE_URL from './components/config/appConfig'
import { jwtDecode } from "jwt-decode";


const AppRoots = () => {
  const [userDetails, setUserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      const decoded = jwtDecode(token);
      const response = await axios.get(`${API_BASE_URL}/users/getUser/${decoded.id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // console.log("response UserDetails==>",response.data)
      setUserDetails(response.data);
      // setIsLoading(false);
    } catch (error) {
      console.error('Error fetching user details:', error);
      // setError(error);
      // setIsLoading(false);
    }
  };

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route index element={<LandingPage />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="projects-reports" element={<ViewReports />} />

          </Route>
          <Route element={<Protected />}>
            <Route path="/projects" element={<Dashboard />}>
              <Route index element={<ProjectsPage />} />
              <Route path="profile" element={<Profile />} />

              <Route element={<ProjectDetailsPage />}>
                <Route path=":projectSlug/active-sprints" element={<ActiveSprintPage />} />
                <Route path=":projectSlug/backlog" element={<BacklogPage />} />
                <Route path=":projectSlug/issues-list" element={<IssuesList />} />
                <Route path=":projectSlug/dashboard" element={<Reports />} />
              
                {/* <Route path=':projectSlug/settings' element={<ProjectSettings />} /> */}

                <Route path=':projectSlug/settings' element={
                  // <RoleProtected 
                  //   allowedRoles={['Project Manager', 'Project Lead', 'Scrum Master','Project Owner']} 
                  //   userId={userDetails._id}
                  // >
                  <ProjectSettings />
                } />
              </Route>


            </Route>
            <Route path="/admin" element={<Dashboard />} >
              <Route element={<ProjectDetailsPage />}>
                <Route path="dashboard" element={<Admin />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppRoots;

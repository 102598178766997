import React, { useState , useEffect, useContext} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tabs, Tab, IconButton, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { NormalSkeleton } from '../../../common/Skeleton';
import BreadcrumbsComponent from '../../../components/BreadcrumbsComponent';
import SearchTextField from '../../../components/SearchTextField';
import API_BASE_URL from '../../../components/config/appConfig';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { colors } from '../../../utilities/colors';
import { useNavigate } from 'react-router-dom';
import { setSelectedProjectBackLogId } from "../../../common/Redux/Reducer/index";

const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
};

const AdminPages = () => {
    const { Projects, Teams, Skeleton } = useSelector((state) => state.ProjamAppSlice);
    const allProjectsList = Projects?.allProjectsList?.data;
    const NavProjectsList = Projects?.allProjectsList?.data?.filter(project => project.active !== "false");
    const [selectedComponent, setSelectedComponent] = useState('projects'); 
    const [allUsers, setAllUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleProjectClick = (projectId) => {
      const project = NavProjectsList?.find((proj) => proj._id === projectId);
      if (project && project.backlogs && project.backlogs.length > 0) {
          const backlogId = project.backlogs[0];
          const projectTitle = project.title;
          const ProjectslugString = project.slugString;
          dispatch(setSelectedProjectBackLogId({ id: backlogId }));
          const selectedProjectDetails = {
              projectId: projectId,
              backlogId: backlogId,
              projectName: projectTitle
          };  
          navigate(`/projects/${ProjectslugString}/active-sprints`, { state: { queryParams: selectedProjectDetails } });
      } else {
          console.error("No backlog found for this project", projectId, project);
      }
  };
 
    const AllProject = [
        {
          field: 'projectName',
          headerName: 'Project Name',
          width: 200,
          renderCell: (params) => {
              const row = params.row;               
              return (
                <div
                     className={`project-title-cls ${row.active === "false" ? "inactive-project" : ""}`}
                      onClick={() => handleProjectClick(row.id)}
                      style={{ cursor: row.active !== "false" ? "pointer" : "default" }}
                      onMouseEnter={(e) => {
                        if (row.active !== "false") {
                            e.target.style.textDecoration = "underline";
                        }
                    }}
                    onMouseLeave={(e) => {
                        if (row.active !== "false") {
                            e.target.style.textDecoration = "none"; 
                        }
                    }}
                  >
                      {params.value}
                  </div>
              );
          },
      },             
        {
          field: 'health',
          headerName: 'Health',
          width: 150,
          renderCell: (params) => {
              const healthStatus = params.value;
              const pillClass = healthStatus === 'At Risk' ? 'pill-warning' :
                                healthStatus === 'On Track' ? 'pill-success' : 'black';
              return (
                  <span className={`pill ${pillClass}`}>
                      {healthStatus}
                  </span>
              );
          }
        },      
        { field: 'createdOn', headerName: 'Created on', width: 179 },
        { field: 'owner', headerName: 'Owner', width: 200 },
        { 
            field: 'status', 
            headerName: 'Status', 
            width: 200,
            renderCell: (params) => {
                const badgeColor = params.value === 'Active' ? 'success' : 'danger';
                return (
                  <Box
                    className={`badge rounded-pill bg-${badgeColor}`}
                    style={{
                      fontSize: '0.75rem', 
                      padding: '3px 6px',
                      color: 'white',
                      fontWeight: '200',
                    }}
                  >
                    {params.value}
                  </Box>
              );
            }
          },
        { field: 'employees', headerName: 'Employees',  width: 150 },
        {
          field: 'actions',
          headerName: 'Actions',
          flex: 2,
          width: 150,
          sortable: false,
          renderCell: (params) => (
            <>
              <IconButton
                sx={{ backgroundColor: 'transparent', color: '#F29F05' }}
                aria-label="edit"
              // onClick={() => handleEdit(params.row.id)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                sx={{ backgroundColor: 'transparent', color: 'red' }}
                aria-label="delete"
              // onClick={() => handleDelete(params.row.id)}
              >
                <DeleteIcon />
              </IconButton>
            </>
          ),
        },
    ];

    const EmployeeList = [
        // { field: 'user', headerName: 'Emp ID', width: 120 },
        { field: 'name', headerName: 'Name', width: 200 },
        { field: 'designation', headerName: 'Designation', width: 150 },
        { field: 'email', headerName: 'Email', width: 300 },
        // { field: 'taggedprojects', headerName: 'Tagged Projects', width: 150 },
        {
          field: 'taggedprojects',
          headerName: 'Tagged Projects',
          width: 300,
          renderCell: (params) => {
            const projects = params.value; 
        
            if (!Array.isArray(projects) || projects.length === 0) {
              return <span>No Projects</span>;
            }
        
            // Render each project as a badge with light grey color
            return (
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', marginTop: "10px", marginBottom: "10px"}}>
                {projects.map((project, index) => (
                  <Box
                    key={index}
                    className="badge rounded-pill"
                    style={{
                      backgroundColor: '#E0E0E0',
                      fontSize: '0.75rem', 
                      padding: '4px 9px',
                      color: 'black',
                      fontWeight: '200',
                      whiteSpace: 'normal',
                    }}        
                  >
                    {project}
                  </Box>
                ))}
              </div>
            );
          },
        },        
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 2,
            width: 150,
            sortable: false,
            renderCell: (params) => (
              <>
                <IconButton
                  sx={{ backgroundColor: 'transparent', color: '#F29F05' }}
                  aria-label="edit"
                // onClick={() => handleEdit(params.row.id)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  sx={{ backgroundColor: 'transparent', color: 'red' }}
                  aria-label="delete"
                // onClick={() => handleDelete(params.row.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            ),
          },
    ];

    useEffect(() => {
        const getAllUsers = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/users`);                  
                const userOptions = response.data.users.map((user) => ({
                    // id: user.UserId ? user.UserId : "-", 
                    id: user._id, 
                    user: user.UserId ? user.UserId : "-",
                    name: user.name,
                    designation: user.department || 'N/A', 
                    email: user.email,
                    // taggedprojects: user.projects ? user.projects.length : 'None',
                    taggedprojects: user.projectsTagged || [],
                    actions: 'View/Edit',
                }));
                setAllUsers(userOptions); 
            } catch (error) {
                console.log(error);
            }
        };

        getAllUsers();
    }, []);
   

    const handleTabChange = (event, newValue) => {
        setSelectedComponent(newValue);
    };

    const handleSearch = (text) => {
        setSearchTerm(text);
      };

      const getRiskPercentage = (sprintsInfo) => {
        if (!sprintsInfo?._id) return "_";
        else{
        const sprint = sprintsInfo;
        const today = new Date();
        const endDate = new Date(sprint.endDate);
        const startDate = new Date(sprint.startDate);
        const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
        const daysLeft = (endDate - today) / (1000 * 60 * 60 * 24);
        
        const daysLeftPercentage = daysLeft >= 0 ? (daysLeft * 100) / totalDays : 0;
      
        const totalIssues = sprint.totalIssues || 0;
        const issuesCompleted = sprint.IssuesDone || 0;
        const issuesLeft = totalIssues - issuesCompleted;
        const issuesLeftPercentage = totalIssues > 0 ? (issuesLeft * 100) / totalIssues : 0;
        const difference = Math.abs(issuesLeftPercentage - daysLeftPercentage);  
        return difference > 30 ? "At Risk" : "On Track";
      };
    }

    const mappedProjects = allProjectsList?.map((project, index) => {
      return {
          id: project._id  , 
          projectName: project.title || 'N/A', 
          health: getRiskPercentage(project.sprintInfo),
          createdOn: formatDate(project.createdAt), 
          owner: project.lead?.name || 'No Lead', 
          status : project.active === "true" ? "Active" : "Inactive",
          employees: project.contributors?.length || 'N/A', 
      };
  }) || [];

    const filteredProjects = mappedProjects.filter(project => 
        project.projectName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredUsers = allUsers.filter(user => 
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const columns = selectedComponent === 'projects' ? AllProject : EmployeeList;
    const rows = selectedComponent === 'projects' ? filteredProjects : filteredUsers || [];

    return (
        <Box>
            <div className="ps-2 ">
                {!Skeleton ? <BreadcrumbsComponent /> : <NormalSkeleton width={200} extraStyles="my-2" />}
            </div>
            <Box className="p-2 d-flex justify-content-between align-items-center">
              <Box>
              {Skeleton ? (
                    <NormalSkeleton variant="rectangular" width={150} height={35} />
                ) : (
                <Tabs
                    value={selectedComponent}
                    onChange={handleTabChange}
                    sx={{
                        '.MuiTabs-indicator': {
                            backgroundColor: '#60A630', 
                        },
                        '.Mui-selected': {
                            color: '#60A630 !important', 
                        },
                    }}
                >
                    <Tab label="All Projects" value="projects"
                    sx={{
                        '&:hover': {
                          color: '#60A630', 
                        },
                      }}
                       />
                    <Tab label="Employee List" value="employee" 
                    sx={{
                        '&:hover': {
                          color: '#60A630', 
                        },
                      }}
                       />
                </Tabs>
                )}
              </Box>
           
                <Box>
                {Skeleton ? (
                    <NormalSkeleton variant="rectangular" width={150} height={35} />
                ) : (
                  <Box className='searchBar'>
                    <SearchTextField 
                    searchTerm={searchTerm}
                    handleSearch={handleSearch}
               />
               </Box>
                )}
                </Box>
                
            </Box>
 
            {/* <Box className="p-2" style={{ height: 400, width: '81%' }}> */}
            <Grid item xs={12} md={12} >
            <Box className="ps-3 pe-3">
                {!Skeleton ? (
                    <DataGrid 
                    rows={rows} 
                    columns={columns}
                    // pageSize={5} 
                    autoHeight
                    // checkboxSelection
                    getRowHeight={selectedComponent === 'employee' ? () => 110 : undefined}    
                    getRowId={(row) => row.id}
                    sx={{
                        "& .MuiDataGrid-columnHeader": {
                          backgroundColor: "#ebeceb",
                        },
                        "& .MuiCheckbox-colorPrimary.Mui-checked": {
                          color: colors.text_green,
                        },
                        "& .MuiDataGrid-row.Mui-selected": {
                          backgroundColor: "#daf0cb",
                        },
                        "& .MuiDataGrid-row.Mui-selected:hover": {
                          backgroundColor: "#bee5a4",
                        },
                        "& .MuiTablePagination-root": {
                          display: "none !important",
                        },
                      }}
                    />
                ) : (
                    <NormalSkeleton width="100%" height={500} />
                )}
            </Box>
            </Grid>
        </Box>
    );
};
 
export default AdminPages;

import React, { useState, useEffect } from 'react';
import { Autocomplete, Avatar, Box, TextField, Typography, Paper } from '@mui/material';
import './modal.css';
import BackgroundLetterAvatarsLogo from '../AvatarLogo';

const SearchableDropdown = ({ workFlowModalTaskData, members, handleAssigneeUpdate, currentRowId, className }) => {
    const [inputValue, setInputValue] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    useEffect(() => {
        if (workFlowModalTaskData?.assignee?.length > 0) {
            setSelectedValue(workFlowModalTaskData?.assignee[0]);
        } else {
            setSelectedValue({ employeeName: 'Unassigned', avatarUrl: workFlowModalTaskData?.assignee?.employeeName });
        }
    }, [workFlowModalTaskData]);

    const handleChange = (event, newValue) => {
        if (newValue?.employeeName === 'Unassigned') {
            setSelectedValue([]);
            newValue = "";
        } else {
            setSelectedValue(newValue);
        }

        if (currentRowId) {
            handleAssigneeUpdate(currentRowId, newValue);
        } else if (workFlowModalTaskData?._id) {
            handleAssigneeUpdate(workFlowModalTaskData?._id, newValue);
        } else {
            console.error("No valid ID available to update assignee.");
        }
    };


    return (
        <Autocomplete
            value={selectedValue}
            onChange={handleChange}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            options={[{ employeeName: 'Unassigned' }, ...members]}
            getOptionLabel={(option) => option.employeeName || 'Unassigned'}
            disableClearable
            PaperComponent={(props) => (
                <Paper {...props} style={{ maxHeight: 'unset', overflowY: 'auto' }} />
            )}
            renderInput={(params) => (
                <TextField
                className={className}
                    {...params}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <Box >
                                <BackgroundLetterAvatarsLogo
                                    LogoTitle={selectedValue?.employeeName === "Unassigned" ? '' : selectedValue?.employeeName}
                                    className="task_Work_avatar-small" />
                            </Box>
                        ),
                    }}
                    // className="custom-autocomplete-input"
                />
            )}
            renderOption={(props, option) => (
                <Box component="li" {...props} className="d-flex align-items-center ms-2">
                    <BackgroundLetterAvatarsLogo LogoTitle={option.employeeName === "Unassigned" ? '' : option?.employeeName} />
                    <Typography sx={{ m: 1, mt: 2 }}>
                        {option.employeeName || 'Unassigned'}
                    </Typography>
                </Box>
            )}
            renderTags={(value, getTagProps) => (
                <Box className="d-flex align-items-center gap-2">
                    <Avatar src={value.avatarUrl} className="task_Work_avatar-small" />
                    <Typography sx={{ me: 1 }} className="task_Work_typography-small">
                        {value.employeeName || 'Unassigned'}
                    </Typography>
                </Box>
            )}
        />
    );
};

export default SearchableDropdown;

import  React , {useEffect, useState} from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  
} from "recharts";
import useMediaQuery from '@mui/material/useMediaQuery'; 
import { Box } from '@mui/material';
import axios from 'axios';
import API_BASE_URL from '../../components/config/appConfig';
import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";

export default function SprintAnalysis() {

  const [data, setdata]=useState([])
  const  [shouldRotateLabels,setShouldRotateLables]=useState(false)
  const { Skeleton,Projects } = useSelector((state) => state.ProjamAppSlice)
  let backlogId = Projects?.selectedProject?.selectedProjectBackLogId
  let projectId = Projects?.selectedProject?.selectedProjectData?._id
  let LastFiveActiveSprint = Projects?.selectedProject?.lastFiveSprintsInfo || []
   
  const isMobile = useMediaQuery('(max-width:600px)');

  useEffect(()=>{
    if(projectId && backlogId && LastFiveActiveSprint.length>0){
      getLastFiveActiveSprints()
    }
  },[projectId,backlogId ,LastFiveActiveSprint])

const getLastFiveActiveSprints=async ()=>{
  try{
   //let response = await axios(`${API_BASE_URL}/sprints/lastFiveActive?backlogId=${backlogId}&projectId=${projectId}`)
    let response = [...LastFiveActiveSprint]
    //console.log(LastFiveActiveSprint , 'last five sprint')
    getSprintAnalysisData(response?.reverse())
  }catch(error){
    console.log(error)
  }
}

  
  const getDuration = async(sprintInfo)=>{
  let startDate=moment(sprintInfo?.startDate);
  let endDate=moment(sprintInfo?.endDate);
  return endDate.diff(startDate, "days")/7;
  }
  
  const getSprintAnalysisData=async(data)=>{
  let barGraphData = await Promise.all(data.map(async(each)=>{
      let weeks= await getDuration(each)
      let sprintName= each?.name
      return {sprintName:sprintName , weeks:weeks}
  }))
  setdata(barGraphData)
   setShouldRotateLables(data.some((item) => item?.name?.length > 15))
  }

  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      <Box sx={{ minWidth: 500 }}> 
    <ResponsiveContainer width="100%"
    height={385}>
    <BarChart
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
     <CartesianGrid  strokeDasharray='3'
    horizontal={true} 
    vertical={false}
      /> 
     
        <XAxis
         dataKey="sprintName"
         angle={isMobile ? -30 : 0} // Slight rotation for mobile (e.g., -30 degrees)
         textAnchor={isMobile ? "end" : "middle"} // Align the text accordingly
         interval={0} // Show all labels
         height={isMobile ? 70 : 30} // Increase height to accommodate rotated labels
         tick={{ fontSize: isMobile ? 10 : 12 }} // Adjust font size for mobile
      />
       
    
      <YAxis tickFormatter={(value) => `${value} weeks`}
        allowDecimals={false} />
      <Tooltip />
      <Bar dataKey="weeks" fill="#82ca9d" />
    </BarChart>
    </ResponsiveContainer>
    </Box>
    </Box>
  );
}

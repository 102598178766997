import { useState, useEffect, useRef } from 'react';
import {
    TextField, Box, Card, Button, FormControlLabel, Checkbox, Typography,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import LanIcon from '@mui/icons-material/Lan';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import BackgroundLetterAvatarsLogo from '../../components/AvatarLogo';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AppTitleLogo from '../../../src/assets/images/projam-icon.svg';
import API_BASE_URL from '../../components/config/appConfig'
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from "react-redux";

const Profile = () => {
    const [isHovered, setIsHovered] = useState(false);
    const [editField, setEditField] = useState(null);
    const [hoveredField, setHoveredField] = useState(null);
    const [role, setRole] = useState('Your Job Title or Role');
    const [department, setDepartment] = useState('Your Department');
    const [organization, setOrganization] = useState('Your Organization');
    const [location, setLocation] = useState('Your Location');
    const [name, setName] = useState('Your Name');
    const [userDetails, setUserDetails] = useState({});
    const [profileImage, setProfileImage] = useState();
    const fileInputRef = useRef(null);
    const [userProject, setUserProject] = useState();

    let { Projects } = useSelector((state) => state.ProjamAppSlice)
  const projectsData = Projects.allProjectsList;

    useEffect(() => {
        getUserDetails();
    }, [])

    // useEffect(() => {
    //     if (userDetails.id) {
    //         getUserProjects();
    //     }
    // }, [userDetails]);

    const getUserDetails = async () => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            const decoded = jwtDecode(token);
            setUserDetails(decoded);
            const response = await axios.get(`${API_BASE_URL}/users/getUser/${decoded.id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            const userData = response.data;
            setRole(userData.role || 'Your Job Title or Role');
            setDepartment(userData.department || 'Your Department');
            setOrganization(userData.organization || 'Your Organization');
            setLocation(userData.location || 'Your Location');
            setName(userData.name || 'Your Name');
        }
    };

    // projects?userid=667c16da0e45473f6e1168a1
    // const getUserProjects = async () => {
    //     try {
    //         const token = localStorage.getItem('accessToken');
    //         const response = await axios.get(`${API_BASE_URL}/projects?userid=${userDetails.id}`, {
    //             headers: { Authorization: `Bearer ${token}` },
    //         });
    //         const projectsData = response.data;
    //         setUserProject(projectsData)
    //         console.log("projectsData",projectsData)
    //     } catch (error) {
    //         console.error('Error fetching user projects:', error);
    //     }
    // };
    // console.log("projectsData", projectsData?.data);
    // setUserProject(projectsData?.data)

    useEffect(() => {
        if (projectsData) {
            setUserProject(projectsData?.data)
        }
    }, [projectsData]);

    const handleMouseEnter = (field) => {
        setHoveredField(field);
    };

    const handleMouseLeave = () => {
        setHoveredField(null);
    };

    const handleEdit = (field) => {
        setEditField(field);
    };

    const tokenAuth = localStorage.getItem('accessToken')
    const handleUpdateDetails = async (field) => {
        let userData = {};
        switch (field) {
            case 'name':
                userData = { name };
                break;
            case 'role':
                userData = { role };
                break;
            case 'department':
                userData = { department };
                break;
            case 'organization':
                userData = { organization };
                break;
            case 'location':
                userData = { location };
                break;
            default:
                break;
        }

        try {
            const response = await axios.put(`${API_BASE_URL}/users/updateUser/${userDetails.id}`, userData, {
                headers: { Authorization: `Bearer ${tokenAuth}` },
            });
            console.log(response);
            setEditField('');
        } catch (error) {
            console.error('Error updating user data', error);
        }
    };

    const handleCameraClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const image = e.target.files[0];
        if (image) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setProfileImage(reader.result);
                // console.log("BAse64 Image==>", reader.result)
            };
            reader.readAsDataURL(image);
        }
    };

    const headers = ['Project Name', 'Keys', 'Created', 'Status'];

    return (<>
        <Box sx={{ backgroundColor: '#f0f0f0', height: "100vh" }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 5 }}>
                <Box sx={{ width: ['100%', '90%'], padding: '5px' }}>
                    <div className='d-flex justify-content-between flex-wrap'>
                        <Box sx={{ width: ['100%', '22%'], height: 'auto', mb: [3, 0] }}>
                            <Card sx={{ mt: 2, p: 2, boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.20) !important", display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <Box className='box-container_absolute mb-3'
                                    onMouseEnter={() => setIsHovered(false)}
                                    onMouseLeave={() => setIsHovered(true)}
                                    onClick={handleCameraClick}
                                >
                                    <BackgroundLetterAvatarsLogo
                                        LogoTitle={userDetails?.name}
                                        isCustomised
                                        RequiredFontSize='55px'
                                        profileImage={profileImage}
                                    />
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        style={{ display: 'none' }}
                                        onChange={handleFileChange}
                                    />
                                </Box>
                                <div style={{ textAlign: 'center' }}>
                                    {editField === 'name' ? (
                                        <Box className="textFieldBtn-container-main">
                                            <TextField
                                                className="editable-textfield"
                                                size="small"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                autoFocus
                                            />
                                            <Box className="button-container">
                                                <Button className="button check-button" variant="contained" onClick={() => handleUpdateDetails('name')}>
                                                    <CheckIcon />
                                                </Button>
                                                <Button className="button close-button" variant="contained" onClick={() => setEditField('')}>
                                                    <CloseIcon />
                                                </Button>
                                            </Box>
                                        </Box>
                                    ) : (
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography variant='h5' className="detail-text" onMouseEnter={() => handleMouseEnter('name')} onMouseLeave={handleMouseLeave} onClick={() => handleEdit('name')}>
                                                {name}
                                            </Typography>
                                            <EditIcon className="edit-icon ms-1" fontSize='15px' onClick={() => handleEdit('name')} />
                                        </Box>
                                    )}
                                </div>
                                <Typography sx={{ textAlign: 'center', mb: 3 }}>{userDetails?.email}</Typography>
                                <Divider variant="middle" component="li" style={{ listStyle: 'none' }} />

                                <Box className="profile-details_main">
                                    <Box sx={{ mt: 2 }}>
                                        <Typography style={{ fontSize: 'small' }}>ABOUT</Typography>
                                    </Box>
                                    <Box
                                        className="detail-row mt-2"
                                    >
                                        <WorkIcon sx={{ fontSize: 20 }} />
                                        {editField === 'role' ? (
                                            <>
                                                <Box className="textFieldBtn-container-main">
                                                    <TextField
                                                        className="editable-textfield"
                                                        size="small"
                                                        value={role}
                                                        onChange={(e) => setRole(e.target.value)}
                                                        // onBlur={() => handleBlur('role')}
                                                        autoFocus
                                                    />
                                                    <Box className="button-container">
                                                        <Button className="button check-button" variant="contained"
                                                            onClick={() => handleUpdateDetails('role')}
                                                        >
                                                            <CheckIcon />
                                                        </Button>
                                                        <Button className="button close-button"
                                                            variant="contained"
                                                            onClick={() => setEditField('')}
                                                        >
                                                            <CloseIcon />
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </>
                                        ) : (
                                            <Box className="detail-text-container">
                                                <Typography className="detail-text ms-2"
                                                    onMouseEnter={() => handleMouseEnter('role')}
                                                    onMouseLeave={handleMouseLeave}
                                                    onClick={() => handleEdit('role')}
                                                >
                                                    {role}
                                                </Typography>
                                                <EditIcon className="edit-icon me-1" fontSize='20px' onClick={() => handleEdit('role')} />
                                            </Box>
                                        )}
                                    </Box>

                                    <Box className="detail-row mt-3">
                                        <LanIcon sx={{ fontSize: 20 }} />
                                        {editField === 'department' ? (
                                            <>
                                                <Box className="textFieldBtn-container-main">
                                                    <TextField
                                                        className="editable-textfield"
                                                        size="small"
                                                        value={department}
                                                        onChange={(e) => setDepartment(e.target.value)}
                                                        autoFocus
                                                    />
                                                    <Box className="button-container">
                                                        <Button className="button check-button" variant="contained"
                                                            onClick={() => handleUpdateDetails('department')}
                                                        >
                                                            <CheckIcon />
                                                        </Button>
                                                        <Button className="button close-button"
                                                            variant="contained"
                                                            onClick={() => setEditField('')}
                                                        >
                                                            <CloseIcon />
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </>
                                        ) : (
                                            <Box className="detail-text-container">
                                                <Typography className="detail-text ms-2"
                                                    onMouseEnter={() => handleMouseEnter('department')}
                                                    onMouseLeave={handleMouseLeave}
                                                    onClick={() => handleEdit('department')}
                                                >
                                                    {department}
                                                </Typography>
                                                <EditIcon className="edit-icon me-1" fontSize='20px' onClick={() => handleEdit('department')} />
                                            </Box>
                                        )}
                                    </Box>

                                    <Box
                                        className="detail-row mt-3"
                                    >
                                        <AccountBalanceIcon sx={{ fontSize: 20 }} />
                                        {editField === 'organization' ? (
                                            <>
                                                <Box className="textFieldBtn-container-main">
                                                    <TextField
                                                        className="editable-textfield"
                                                        size="small"
                                                        value={organization}
                                                        onChange={(e) => setOrganization(e.target.value)}
                                                        // onBlur={() => handleBlur('organization')}
                                                        autoFocus
                                                    />
                                                    <Box className="button-container">
                                                        <Button className="button check-button" variant="contained"
                                                            onClick={() => handleUpdateDetails('organization')}
                                                        >
                                                            <CheckIcon />
                                                        </Button>
                                                        <Button className="button close-button"
                                                            variant="contained"
                                                            onClick={() => setEditField('')}
                                                        >
                                                            <CloseIcon />
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </>
                                        ) : (
                                            <Box className="detail-text-container">
                                                <Typography className="detail-text ms-2"
                                                    onMouseEnter={() => handleMouseEnter('organization')}
                                                    onMouseLeave={handleMouseLeave}
                                                    onClick={() => handleEdit('organization')}
                                                >
                                                    {organization}
                                                </Typography>
                                                <EditIcon className="edit-icon me-1" fontSize='20px' onClick={() => handleEdit('organization')} />
                                            </Box>
                                        )}
                                    </Box>
                                    <Box className="detail-row mt-3 mb-3">
                                        <LocationOnIcon sx={{ fontSize: 20 }} />
                                        {editField === 'location' ? (
                                            <>
                                                <Box className="textFieldBtn-container-main">
                                                    <TextField
                                                        className="editable-textfield"
                                                        size="small"
                                                        value={location}
                                                        onChange={(e) => setLocation(e.target.value)}
                                                        autoFocus
                                                    />
                                                    <Box className="button-container">
                                                        <Button className="button check-button" variant="contained"
                                                            onClick={() => handleUpdateDetails('location')}
                                                        >
                                                            <CheckIcon />
                                                        </Button>
                                                        <Button className="button close-button"
                                                            variant="contained"
                                                            onClick={() => setEditField('')}
                                                        >
                                                            <CloseIcon />
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </>
                                        ) : (
                                            <Box className="detail-text-container">

                                                <Typography className="detail-text ms-2"
                                                    onMouseEnter={() => handleMouseEnter('location')}
                                                    onMouseLeave={handleMouseLeave}
                                                    onClick={() => handleEdit('location')}
                                                >
                                                    {location}
                                                </Typography>
                                                <EditIcon className="edit-icon me-1" fontSize='20px' onClick={() => handleEdit('location')} />
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Card>
                        </Box>
                        <Box sx={{ width: ['100%', '76%'], height: 'auto' }}>
                            <Card sx={{ mt: 2, p: 3, boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.20) !important" }}>
                                <Typography variant='h6' className='fw-bold'>Current Project</Typography>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {headers.map((header, index) => (
                                                    <TableCell key={index} align="left">{header}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {userProject?.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell align="left">{row.title}</TableCell>
                                                    <TableCell align="left">{row.key}</TableCell>
                                                    <TableCell align="left">{new Date(row.createdAt).toLocaleDateString()}</TableCell>
                                                    <TableCell align="left">
                                                        {row.active === "true" ? (
                                                            <span className="badge rounded-pill bg-success">Active</span>
                                                        ) : (
                                                            <span className="badge rounded-pill bg-danger">Inactive</span>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Box>
                    </div>
                </Box>
            </Box>
        </Box>
    </>
    );
};

export default Profile;





{/* <Box>
                <Box sx={{ maxWidth: '100%', height: 'auto' }}>
                    <Box
                        className={`box-container-main ${isHovered ? 'hovered' : ''}`}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        {isHovered && (
                            <Typography
                                variant="h6"
                                sx={{
                                    position: 'absolute',
                                }}
                            >
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <ImageIcon sx={{ fontSize: 40 }} />
                                </Box>
                                Add your header image
                            </Typography>
                        )}
                        <Box className='box-container_absolute'
                            onMouseEnter={() => setIsHovered(false)}
                            onMouseLeave={() => setIsHovered(true)}
                        >
                            <Box className='icon-container' onClick={handleCameraClick}>
                                <CameraAltIcon className='camera-icon' />
                            </Box>
                            <BackgroundLetterAvatarsLogo
                                LogoTitle={userDetails?.name}
                                isCustomised
                                RequiredFontSize='55px'
                                profileImage={profileImage}
                            />
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box> */}



{/* <div >
                                {editField === 'name' ? (
                                    <>
                                        <Box className="textFieldBtn-container-main">
                                            <TextField
                                                className="editable-textfield"
                                                size="small"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                // onBlur={() => handleBlur('role')}
                                                autoFocus
                                            />
                                            <Box className="button-container">
                                                <Button className="button check-button" variant="contained"
                                                    onClick={() => handleUpdateDetails('name')}
                                                >
                                                    <CheckIcon />
                                                </Button>
                                                <Button className="button close-button"
                                                    variant="contained"
                                                    onClick={() => setEditField('')}
                                                >
                                                    <CloseIcon />
                                                </Button>
                                            </Box>
                                        </Box>
                                    </>
                                ) : (
                                    <Typography variant='h5'
                                        onMouseEnter={() => handleMouseEnter('name')}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={() => handleEdit('name')}
                                    >
                                        {name}
                                    </Typography>
                                )}
                            </div> */}
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Tooltip } from "@mui/material";

const SelectMenuItems = ({
  title = "",
  backGroundColor = "transparent",
  ItemsList = [],
  RequiredFontSize = "inherit",
  showHeader = true,
  showEndIcon = true,
  icon = null,
  handleStatuUpdate = () => { },
  handlePriorityUpdate = () => { },
  handleClearFilters = () => { },
  className = "",
  isEpicMenuList = false

}) => {
  const [gallery, setGallery] = useState(null);
  const [selectedValue, setSelectedValue] = useState(title);

  useEffect(() => {
    setSelectedValue(title);
  }, [title]);


  const handleClick = (event) => {
    setGallery(event.currentTarget);
  };


  const handleChangeSelectedValue = (value) => {
    const displayValue = value.name || value.title;
    if (title === "Epic") {
      handleClearFilters()
    }
    setSelectedValue(displayValue);
    handleStatuUpdate(displayValue);
    handlePriorityUpdate(displayValue);
    setGallery(null);
    // setSelectedValue(value.name);
    // handleStatuUpdate(value.name);
    // handlePriorityUpdate(value.name)
    // setGallery(null);

  };

  const handleClose = () => {
    setGallery(null);
  };

  // const handleClear = () => {
  //   setGallery(null);
  //   console.log("Clear clicked");
  // };

  // const getDisplayValue = (value) => {
  //   if (value === "In Review") return "Code Review";
  //   if (value === "QA") return "In Testing";
  //   return value;
  // };

  const open = Boolean(gallery);

  let btnStyles = { backgroundColor: backGroundColor, color: "#7B7C7B" };

  switch (selectedValue) {
    case "To Do":
      btnStyles = { backgroundColor: "#E5E4EC", color: "#515E69" };
      break;
    case "In Progress":
    // case "QA READY":
    case "Code Review":
      btnStyles = { backgroundColor: "#E7EDFE", color: "#0050B4" };
      break;
    case "In Testing":
    case "Production Ready":
      btnStyles = { backgroundColor: "#BFDDF5", color: "#0050B4" };
      break;
    case "Done":
      btnStyles = { backgroundColor: "#E2FCF0", color: "#006B56" };
      break;
    default:
      btnStyles = { backgroundColor: backGroundColor, color: "#7B7C7B" };
  }

  return (
    <div>
      <Button
        onClick={handleClick}
        className={`${className} ${backGroundColor ? "mx-2" : ""}`}
        startIcon={icon}
        endIcon={
          showEndIcon ? open ? <ExpandLessIcon /> : <ExpandMoreIcon /> : null
        }
        style={{
          boxShadow: "none",
          textTransform: "none",
          fontSize: RequiredFontSize,
          ...btnStyles,
        }}
        size="small"
      >
        {selectedValue}
      </Button>
      <Menu anchorEl={gallery} open={open} onClose={handleClose}>
        {(ItemsList && ItemsList?.length > 0) ? ItemsList?.map((eachItem, index) => (
          isEpicMenuList ? <Tooltip key={`${index}-${eachItem?.name}`} title={eachItem?.summary} placement="right">
            <MenuItem
              onClick={() => handleChangeSelectedValue(eachItem)}
              key={index}
              className="px-3"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontSize: "13px",
              }}
            >
              {(eachItem?.icon) ? (eachItem?.icon) : ''}
              {eachItem?.name || eachItem?.title}
            </MenuItem></Tooltip> : <MenuItem
              onClick={() => handleChangeSelectedValue(eachItem)}
              key={index}
              className="px-3"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                fontSize: "13px",
              }}
            >
            {(eachItem?.icon) ? (eachItem?.icon) : ''}
            {eachItem?.name || eachItem?.title}
          </MenuItem>
        )) : <MenuItem onClick={() => handleClose()}> No Data Available</MenuItem>}
      </Menu>
    </div>
  );
};

export default SelectMenuItems;
